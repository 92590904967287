/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import { UserService } from "../../UserService";
import decode from "jwt-decode";
import ShowTables from "../Tables/ShowTables.js";

var ps;

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
    totalUnRead: "",
    totalOtherReservations: null,
    totalEventReservations: null,
    showTable: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

  openTables = () => {
    this.setState({
      showTable: true,
    });
  };
  setShowTable = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        showTable: !prevState.showTable,
      };
    });
  async loadData() {
    const userToken = localStorage.getItem("token");
    const loggedInUser = decode(userToken);
    if (loggedInUser.user.role.toLowerCase() === "admin") {
      const userService = new UserService();
      const notifications = await userService.getAdminNotifications();
      const notificationCounts = await userService.getPendingNotifications();
      console.log("load");
      this.setState({ totalUnRead: notifications?.totalUnread });
      this.setState({
        totalOtherReservations: notificationCounts?.data?.otherReservations,
      });
      this.setState({
        totalEventReservations: notificationCounts?.data?.eventReservations,
      });
    }
  }
  componentDidMount() {
    this.loadData();
    this.interval = setInterval(() => this.loadData(), 600000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      const userToken = localStorage.getItem("token");
      const loggedInUser = decode(userToken);

      if (loggedInUser.user.role.toLowerCase() === "admin") {
        if (!prop.disable) {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={() => {
                  this.closeCollapse();
                }}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
                {prop.count && (
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-pill badge-info"
                  >
                    {/* {prop.count} */}
                    {this.state[prop.count]}
                  </span>
                )}
                {/* extra for notifications */}
                {prop.name == "Njoftimet" && this.state.totalUnRead > 0 ? (
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-pill badge-info"
                  >
                    {this.state.totalUnRead}
                  </span>
                ) : (
                  ""
                )}
              </NavLink>
            </NavItem>
          );
        }
      } else {
        // author
        if (!prop.disable && prop.author) {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={() => {
                  this.closeCollapse();
                }}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}

                {/* extra for notifications */}
                {prop.name == "Njoftimet" && this.state.totalUnRead > 0 ? (
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-pill badge-info"
                  >
                    {this.state.totalUnRead}
                  </span>
                ) : (
                  ""
                )}
              </NavLink>
            </NavItem>
          );
        }
      }
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    return (
      <>
        <Navbar
          className="navbar-vertical fixed-left navbar-light bg-white"
          expand="md"
          id="sidenav-main"
        >
          <Container fluid>
            {/* Toggler */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* Brand */}
            {logo ? (
              <NavbarBrand className="pt-0" {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
            ) : null}
            <Nav className="align-items-center d-md-none">
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <Media className="align-items-center dropdown-user">
                    <span className="avatar avatar-sm rounded-circle">
                      <b>{loggedInUser.user.name.charAt(0)}</b>
                    </span>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.removeItem("token");
                      window.location.href = "/auth/login";
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Çkyqu</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
            {/* Collapse */}
            <Collapse navbar isOpen={this.state.collapseOpen}>
              {/* Collapse header */}
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  {logo ? (
                    <Col className="collapse-brand" xs="6">
                      {logo.innerLink ? (
                        <Link to={logo.innerLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </Link>
                      ) : (
                        <a href={logo.outterLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </a>
                      )}
                    </Col>
                  ) : null}
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={this.toggleCollapse}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav navbar>{this.createLinks(routes)}</Nav>

              <Button
                style={{
                  position: "absolute",
                  bottom: "10px",
                  minWidth: "75%",
                }}
                color="primary"
                onClick={this.openTables}
              >
                CAKTO TAVOLINAT
              </Button>
            </Collapse>
          </Container>
        </Navbar>
        {this.state.showTable && (
          <ShowTables
            showTable={this.state.showTable}
            setShowTable={this.setShowTable}
            showBothReservations={true}
            fromEvent={false}
          />
        )}
      </>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
