/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import { UserService } from "../UserService";
import moment from "moment";
import decode from "jwt-decode";

class Suggestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: [],
      reservation: null,
      page: 1,
      activePage: 1,
      detailsModal: false,
      approveDisapprove: false,
      activeFilter: "all",
    };
  }

  async componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
    const { approveDisapprove, page } = this.state;
    const userService = new UserService();
    const reservations = await userService.getAdminSuggestions(page);
    if (reservations && reservations.data.length === 10) {
      this.setState((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        };
      });
    }
    if (reservations && reservations.data) {
      this.setState({
        reservations: reservations,
      });
    }
  }

  viewDetails = async (id) => {
    const userService = new UserService();
    const reservation = await userService.getAdminSuggestion(id);
    if (reservation && reservation.data && reservation.data.data) {
      this.setState({ reservation: reservation.data.data, detailsModal: true });
    }
  };

  pageClick = async (page) => {
    const userService = new UserService();
    const { activeFilter } = this.state;
    let reservationsPages = [];
    if (activeFilter === "1" || activeFilter === "2") {
      reservationsPages = await userService.getAdminSuggestions(
        page,
        activeFilter
      );
    } else {
      reservationsPages = await userService.getAdminSuggestions(page);
    }
    if (reservationsPages.data.length) {
      this.setState({
        reservations: reservationsPages,
        activePage: page,
      });
    }
  };

  toggle = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        detailsModal: !prevState.detailsModal,
      };
    });

  render() {
    const { reservations, page, activePage, detailsModal, reservation } =
      this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Sygjerimet</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Emri Mbiemri</th>
                      <th scope="col">Email</th>
                      <th scope="col">Përshkrimi</th>
                      <th scope="col">Data</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {reservations &&
                      reservations.data &&
                      reservations.data.length &&
                      reservations.data.map((reservation) => (
                        <tr key={reservation.id}>
                          <td>{reservation.name || "Ska Emer"}</td>
                          <td>{reservation.email || "Ska email"}</td>
                          <td>{reservation.description || "Ska shenim"}</td>
                          <td>
                            {moment(parseInt(reservation.created_at)).format(
                              "DD/MM/YYYY"
                            ) || "Ska date"}
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) =>
                                    this.viewDetails(reservation._id)
                                  }
                                >
                                  Detajet
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {Array.apply(null, { length: activePage + 1 }).map(
                        (p, index) => {
                          return (
                            <PaginationItem
                              key={index}
                              className={
                                index + 1 === activePage ? "active" : ""
                              }
                            >
                              <PaginationLink
                                href="#"
                                onClick={(e) => this.pageClick(index + 1)}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        }
                      )}

                      {/*<PaginationItem>*/}
                      {/*  <PaginationLink*/}
                      {/*    href="#pablo"*/}
                      {/*    onClick={e => e.preventDefault()}*/}
                      {/*  >*/}
                      {/*    2 <span className="sr-only">(current)</span>*/}
                      {/*  </PaginationLink>*/}
                      {/*</PaginationItem>*/}
                      {/*<PaginationItem>*/}
                      {/*  <PaginationLink*/}
                      {/*    href="#pablo"*/}
                      {/*    onClick={e => e.preventDefault()}*/}
                      {/*  >*/}
                      {/*    3*/}
                      {/*  </PaginationLink>*/}
                      {/*</PaginationItem>*/}
                      {/*<PaginationItem>*/}
                      {/*  <PaginationLink*/}
                      {/*    href="#pablo"*/}
                      {/*    onClick={e => e.preventDefault()}*/}
                      {/*  >*/}
                      {/*    <i className="fas fa-angle-right" />*/}
                      {/*    <span className="sr-only">Next</span>*/}
                      {/*  </PaginationLink>*/}
                      {/*</PaginationItem>*/}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <div>
          <Modal isOpen={detailsModal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Detajet e sygjerimit</ModalHeader>
            <ModalBody>
              <p>
                <b>Emri:</b> {reservation && reservation.name}
              </p>
              <p>
                <b>Email:</b> {reservation && reservation.email}
              </p>
              <p>
                <b>Data:</b>{" "}
                {reservation &&
                  moment(parseInt(reservation.created_at)).format("DD/MM/YYYY")}
              </p>
              <p>
                <b>Përshkrimi:</b> {reservation && reservation.description}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Mbyll
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}

export default Suggestions;
