import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
} from "reactstrap";

import decode from "jwt-decode";
import { UserService } from "../../UserService";

import SingleTable from "./SingleTable";
import ListInTable from "./ListInTable";
import TableModal from "./TableModal";

const initialTables1 = [
  {
    tableId: "terasa-40",
    positionTop: "400px",
    positionLeft: "200px",
    shape: "square",
  },
  {
    tableId: "terasa-41",
    positionTop: "400px",
    positionLeft: "350px",
    shape: "square",
  },
  {
    tableId: "terasa-42",
    positionTop: "400px",
    positionLeft: "500px",
    shape: "square",
  },
  {
    tableId: "terasa-43",
    positionTop: "400px",
    positionLeft: "650px",
    shape: "square",
  },
  {
    tableId: "terasa-44",
    positionTop: "400px",
    positionLeft: "800px",
    shape: "square",
  },
  {
    tableId: "terasa-45",
    positionTop: "400px",
    positionLeft: "950px",
    shape: "square",
  },
  {
    tableId: "terasa-46",
    positionTop: "350px",
    positionLeft: "1100px",
    shape: "square",
  },
  {
    tableId: "terasa-47",
    positionTop: "160px",
    positionLeft: "1100px",
    shape: "square",
  },
  {
    tableId: "terasa-48",
    positionTop: "50px",
    positionLeft: "1100px",
    shape: "square",
  },
  {
    tableId: "terasa-49",
    positionTop: "50px",
    positionLeft: "800px",
    shape: "square",
  },
  {
    tableId: "terasa-51",
    positionTop: "50px",
    positionLeft: "650px",
    shape: "square",
  },
  {
    tableId: "terasa-52",
    positionTop: "160px",
    positionLeft: "650px",
    shape: "square",
  },
  {
    tableId: "terasa-53",
    positionTop: "50px",
    positionLeft: "500px",
    shape: "square",
  },
  {
    tableId: "terasa-54",
    positionTop: "160px",
    positionLeft: "500px",
    shape: "square",
  },
  {
    tableId: "terasa-55",
    positionTop: "50px",
    positionLeft: "350px",
    shape: "square",
  },
  {
    tableId: "terasa-59",
    positionTop: "50px",
    positionLeft: "50px",
    shape: "square",
  },
  {
    tableId: "terasa-60",
    positionTop: "350px",
    positionLeft: "50px",
    shape: "square",
  },
  {
    tableId: "terasa-61",
    positionTop: "500px",
    positionLeft: "50px",
    shape: "square",
  },
  {
    tableId: "terasa",
    positionTop: "300px",
    positionLeft: "500px",
    shape: "noshape",
  },
  {
    tableId: "tav-1",
    positionTop: "1550px",
    positionLeft: "50px",
    shape: "circle",
  },
  {
    tableId: "tav-2",
    positionTop: "1250px",
    positionLeft: "50px",
    shape: "square",
  },
  {
    tableId: "tav-3",
    positionTop: "1100px",
    positionLeft: "50px",
    shape: "square",
  },
  {
    tableId: "tav-4",
    positionTop: "950px",
    positionLeft: "50px",
    shape: "square",
  },
  {
    tableId: "tav-5",
    positionTop: "800px",
    positionLeft: "50px",
    shape: "square",
  },
  {
    tableId: "tav-6",
    positionTop: "650px",
    positionLeft: "50px",
    shape: "circle",
  },
  {
    tableId: "tav-7",
    positionTop: "950px",
    positionLeft: "200px",
    shape: "square",
  },
  {
    tableId: "tav-8",
    positionTop: "1100px",
    positionLeft: "200px",
    shape: "square",
  },
  {
    tableId: "line1",
    positionTop: "925px",
    positionLeft: "320px",
    shape: "line",
  },
  {
    tableId: "line2",
    positionTop: "925px",
    positionLeft: "870px",
    shape: "line",
  },
  {
    tableId: "tav-10",
    positionTop: "1100px",
    positionLeft: "350px",
    shape: "circle",
  },
  {
    tableId: "tav-11",
    positionTop: "950px",
    positionLeft: "350px",
    shape: "circle",
  },
  {
    tableId: "tav-12",
    positionTop: "650px",
    positionLeft: "350px",
    shape: "square",
  },
  {
    tableId: "tav-13",
    positionTop: "650px",
    positionLeft: "550px",
    shape: "square",
  },
  {
    tableId: "tav-14",
    positionTop: "650px",
    positionLeft: "750px",
    shape: "square",
  },
  {
    tableId: "tav-15",
    positionTop: "950px",
    positionLeft: "750px",
    shape: "circle",
  },
  {
    tableId: "tav-16",
    positionTop: "1100px",
    positionLeft: "750px",
    shape: "circle",
  },
  {
    tableId: "tav-18",
    positionTop: "1025px",
    positionLeft: "500px",
    shape: "square",
  },
  {
    tableId: "tav-17",
    positionTop: "1025px",
    positionLeft: "600px",
    shape: "square",
  },
  {
    tableId: "tav-19",
    positionTop: "1100px",
    positionLeft: "900px",
    shape: "square",
  },
  {
    tableId: "tav-20",
    positionTop: "950px",
    positionLeft: "900px",
    shape: "square",
  },
  {
    tableId: "tav-22",
    positionTop: "650px",
    positionLeft: "1050px",
    shape: "circle",
  },
  {
    tableId: "tav-23",
    positionTop: "800px",
    positionLeft: "1050px",
    shape: "circle",
  },
  {
    tableId: "tav-24",
    positionTop: "950px",
    positionLeft: "1050px",
    shape: "circle",
  },
  {
    tableId: "tav-25",
    positionTop: "1100px",
    positionLeft: "1050px",
    shape: "circle",
  },
  {
    tableId: "tav-27",
    positionTop: "1550px",
    positionLeft: "1050px",
    shape: "square",
  },
  {
    tableId: "tav-28",
    positionTop: "950px",
    positionLeft: "1200px",
    shape: "square",
  },
  {
    tableId: "tav-29",
    positionTop: "1100px",
    positionLeft: "1200px",
    shape: "square",
  },
  {
    tableId: "tav-30",
    positionTop: "1400px",
    positionLeft: "1200px",
    shape: "square",
  },
  {
    tableId: "tav-31",
    positionTop: "1550px",
    positionLeft: "1200px",
    shape: "square",
  },
  {
    tableId: "salla",
    positionTop: "800px",
    positionLeft: "550px",
    shape: "noshape",
  },

  {
    tableId: "banak-112",
    positionTop: "1300px",
    positionLeft: "300px",
    shape: "square",
  },

  {
    tableId: "banak-111",
    positionTop: "1400px",
    positionLeft: "300px",
    shape: "square",
  },
  {
    tableId: "banak-110",
    positionTop: "1500px",
    positionLeft: "300px",
    shape: "square",
  },
  {
    tableId: "banak-113",
    positionTop: "1300px",
    positionLeft: "400px",
    shape: "square",
  },
  {
    tableId: "banak-114",
    positionTop: "1300px",
    positionLeft: "500px",
    shape: "square",
  },
  {
    tableId: "banak-115",
    positionTop: "1300px",
    positionLeft: "800px",
    shape: "square",
  },
  {
    tableId: "banak-116",
    positionTop: "1300px",
    positionLeft: "900px",
    shape: "square",
  },
  {
    tableId: "banak-117",
    positionTop: "1400px",
    positionLeft: "900px",
    shape: "square",
  },
  {
    tableId: "banaku",
    positionTop: "1450px",
    positionLeft: "550px",
    shape: "noshape",
  },
];

const initialTables2 = [
  {
    tableId: "tav-01-1",
    positionTop: "710px",
    positionLeft: "100px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "tav-01-2",
    positionTop: "710px",
    positionLeft: "200px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "tav-01-3",
    positionTop: "710px",
    positionLeft: "300px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "tav-02-1",
    positionTop: "860px",
    positionLeft: "100px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "tav-02-2",
    positionTop: "860px",
    positionLeft: "200px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "tav-02-3",
    positionTop: "860px",
    positionLeft: "300px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "tav-03",
    positionTop: "820px",
    positionLeft: "950px",
    shape: "square",
  },
  {
    tableId: "tav-04",
    positionTop: "710px",
    positionLeft: "950px",
    shape: "square",
  },
  {
    tableId: "tav-05",
    positionTop: "600px",
    positionLeft: "650px",
    shape: "square",
  },
  {
    tableId: "tav-06",
    positionTop: "490px",
    positionLeft: "650px",
    shape: "square",
  },
  {
    tableId: "tav-07",
    positionTop: "380px",
    positionLeft: "650px",
    shape: "square",
  },
  {
    tableId: "tav-08",
    positionTop: "270px",
    positionLeft: "650px",
    shape: "square",
  },
  {
    tableId: "tav-09",
    positionTop: "160px",
    positionLeft: "650px",
    shape: "square",
  },
  {
    tableId: "tav-10",
    positionTop: "270px",
    positionLeft: "950px",
    shape: "square",
  },
  {
    tableId: "tav-11",
    positionTop: "160px",
    positionLeft: "950px",
    shape: "square",
  },

  {
    tableId: "tav-12",
    positionTop: "50px",
    positionLeft: "500px",
    shape: "square",
  },
  {
    tableId: "tav-13",
    positionTop: "50px",
    positionLeft: "350px",
    shape: "square",
  },
  {
    tableId: "tav-14",
    positionTop: "50px",
    positionLeft: "200px",
    shape: "square",
  },
  {
    tableId: "tav-15",
    positionTop: "50px",
    positionLeft: "50px",
    shape: "square",
  },
  {
    tableId: "tav-16-1",
    positionTop: "270px",
    positionLeft: "100px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "tav-16-2",
    positionTop: "270px",
    positionLeft: "200px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "tav-16-3",
    positionTop: "270px",
    positionLeft: "300px",
    shape: "square",
    width: "100px",
    height: "100px",
  },
  {
    tableId: "hyrja",
    positionTop: "930px",
    positionLeft: "650px",
    shape: "noshape",
  },
  {
    tableId: "shanku",
    positionTop: "490px",
    positionLeft: "950px",
    shape: "noshape",
  },
  {
    tableId: "line1",
    positionTop: "140px",
    positionLeft: "1070px",
    shape: "line",
    width: "10px",
    height: "250px",
  },
  {
    tableId: "line2",
    positionTop: "390px",
    positionLeft: "930px",
    shape: "line",
    width: "150px",
    height: "10px",
  },
  {
    tableId: "line3",
    positionTop: "390px",
    positionLeft: "930px",
    shape: "line",
    width: "10px",
    height: "280px",
  },
  {
    tableId: "line4",
    positionTop: "670px",
    positionLeft: "930px",
    shape: "line",
    width: "150px",
    height: "10px",
  },
  {
    tableId: "line5",
    positionTop: "670px",
    positionLeft: "1070px",
    shape: "line",
    width: "10px",
    height: "270px",
  },
];

const ShowTables = ({
  showTable,
  setShowTable,
  reservation,
  showBothReservations,
  fromEvent,
}) => {
  const [mainModalRender, setMainModalRender] = useState(false);

  const [initialTables, setInitialTables] = useState([]);

  const [fetchedTableId, setFetchedTableId] = useState("");

  const [tableModalOpen, setTableModalOpen] = useState(false);

  const toggleMainModalRender = () => {
    setMainModalRender((prevModal) => !prevModal);
  };

  const toggleTableModal = () => {
    setTableModalOpen((prevModal) => !prevModal);
  };
  const addTable = (id) => {
    setTableModalOpen(true);
    setFetchedTableId(id);
  };

  const [listTableModalOpen, setListTableModalOpen] = useState(false);
  const toggleListTableModalOpen = () => {
    setListTableModalOpen((prevModal) => !prevModal);
  };
  const listTable = (id) => {
    setListTableModalOpen(true);
    setFetchedTableId(id);
  };

  const [isAdmin, setIsAdmin] = useState(true);
  const [restaurant, setRestaurant] = useState(
    reservation ? reservation.restaurant : "1"
  );
  const [isEvent, setIsEvent] = useState(fromEvent);
  const [date, setDate] = useState(
    reservation ? reservation.date : new Date().toISOString()
  );
  const [tables, setTables] = useState([]);

  const toggleModal = () => {
    setShowTable((prevModal) => !prevModal);

    if (
      window.location.pathname === "/admin/reservations" ||
      window.location.pathname === "/admin/events-reservations"
    )
      window.location.reload(true);
  };
  const toggleEvent = (event) => {
    if (event.currentTarget.id === "rezervime") {
      setIsEvent(false);
    }

    if (event.currentTarget.id === "rezervime-evente") {
      setIsEvent(true);
    }
  };

  const toggleRestaurant = (event) => {
    if (event.currentTarget.id === "rrugab") {
      setRestaurant("1");
    }

    if (event.currentTarget.id === "qender") {
      setRestaurant("2");
    }
  };

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin" &&
      loggedInUser.user.role.toLowerCase() !== "author"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }

    if (
      loggedInUser?.user?.role.toLowerCase() === "admin" &&
      loggedInUser?.user?.restaurant === "2"
    ) {
      setRestaurant("2");
      setIsAdmin(false);
    }
    if (
      loggedInUser?.user?.role.toLowerCase() === "admin" &&
      loggedInUser?.user?.restaurant === "1"
    ) {
      setRestaurant("1");
      setIsAdmin(false);
    }
    if (restaurant === "1") {
      setInitialTables(initialTables1);
    }
    if (restaurant === "2") {
      setInitialTables(initialTables2);
    }
    const fetchTables = async () => {
      const userService = new UserService();
      const tables = await userService.getTables(date, restaurant, isEvent);
      setTables(tables?.data);
    };
    fetchTables();
  }, [restaurant, isEvent, date, mainModalRender]);

  return (
    <>
      <Modal isOpen={showTable} toggle={toggleModal} className="tables">
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <div className="header-info">
          <div className="tabs">
            {isAdmin && showBothReservations && (
              <div
                className="restaurants"
                style={{ margin: "10px 0", display: "flex" }}
              >
                <a
                  href="#0"
                  id="rrugab"
                  className={`tab ${restaurant === "1" ? "active" : ""}`}
                  onClick={toggleRestaurant}
                >
                  Rruga B
                </a>
                <a
                  href="#0"
                  id="qender"
                  className={`tab ${restaurant === "2" ? "active" : ""}`}
                  onClick={toggleRestaurant}
                >
                  Qender
                </a>
              </div>
            )}
            {!isAdmin && (
              <div className="restaurants">
                <h2>{restaurant === "1" ? "Rruga B" : "Qender"}</h2>
              </div>
            )}
            {showBothReservations && (
              <div
                className="reservations"
                style={{ margin: "10px 0", display: "flex" }}
              >
                <a
                  href="#0"
                  id="rezervime"
                  className={`tab ${!isEvent && "active"}`}
                  onClick={toggleEvent}
                >
                  Rezervimet
                </a>
                <a
                  href="#0"
                  id="rezervime-evente"
                  className={`tab ${isEvent && "active"}`}
                  onClick={toggleEvent}
                >
                  Rezervimet [Evente]
                </a>
                <a className="tab">{tables.length}</a>
              </div>
            )}
          </div>
          {showBothReservations && (
            <div className="date">
              <Form role="form" style={{ marginBottom: "-40px" }}>
                <FormGroup>
                  <Label for="data">Data:</Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Data"
                      id="data"
                      onChange={(e) =>
                        setDate(new Date(e.target.value || "").toISOString())
                      }
                      type="date"
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </div>
          )}
        </div>

        <ModalBody>
          {initialTables.map((table, index) => {
            let hasListTable = false;
            tables.forEach((item) => {
              if (table?.tableId === item?.tableId) {
                hasListTable = true;
              }
            });

            return (
              <SingleTable
                key={index}
                table={table}
                hasListTable={hasListTable}
                addTable={addTable}
                listTable={listTable}
              />
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Mbyll
          </Button>{" "}
        </ModalFooter>
      </Modal>

      {tableModalOpen && (
        <TableModal
          toggleMainModalRender={toggleMainModalRender}
          tableModalOpen={tableModalOpen}
          toggleTableModal={toggleTableModal}
          id={fetchedTableId}
          restaurant={restaurant}
          isEvent={isEvent}
          date={date}
          reservation={reservation}
        />
      )}
      {listTableModalOpen && (
        <ListInTable
          toggleMainModalRender={toggleMainModalRender}
          listTableModalOpen={listTableModalOpen}
          toggleListTableModalOpen={toggleListTableModalOpen}
          id={fetchedTableId}
          restaurant={restaurant}
          isEvent={isEvent}
          date={date}
        />
      )}
    </>
  );
};
export default ShowTables;
