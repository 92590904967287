/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import ShowTables from "../components/Tables/ShowTables.js";
import { UserService } from "../UserService";
import moment from "moment";
import decode from "jwt-decode";

import { DelayInput } from "react-delay-input";

const approveMessages = {
  val1: "Faleminderit që na kontaktuat për rezervim! Rezervimi u aprovua, ju mirëpresim!",
  tjeter: "Tjetër",
};
const rejectMessages = {
  val1: "Faleminderit që na kontaktuat për rezervim! Fatkeqësisht nuk kemi vende të lira për këtë event. Faleminderit për mirëkuptimin!",
  val2: "Faleminderit që na kontaktuat për rezervim! Rezervimi është refuzuar, fatkeqësisht nuk kemi vende të lira për këtë event. Ju mirëpresim në eventet tjera.Faleminderit për mirëkuptimin!",
  val3: "Faleminderit që na kontaktuat për rezervim! Eventi është anuluar. Ju mirëpresim në eventet tjera. Faleminderit për mirëkuptimin!",
  tjeter: "Tjetër",
};

class EventReservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: [],
      reservation: null,
      page: 1,
      activePage: 1,
      detailsModal: false,
      updateModal: false,
      approveDisapprove: false,
      activeFilter: "all",
      activeFilterStatus: "all",
      messageModal: false,
      modalType: "",
      messageValue: "",
      messageValueOther: "",
      showTable: false,
      search: "",
      reservationType: true,
      reservationHour: "",
      events: [],
      eventId: "",
    };
  }

  async componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
    const { approveDisapprove, page } = this.state;
    const userService = new UserService();
    const reservations = await userService.getEventReservations(
      page,
      this.state.search
    );
    if (reservations && reservations.data.length === 10) {
      this.setState((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        };
      });
    }
    if (reservations && reservations.data) {
      this.setState({
        reservations: reservations,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.showTable === true) {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        window.location.href = "/auth/login";
      }
      const loggedInUser = decode(userToken);
      if (
        loggedInUser &&
        loggedInUser.user.role &&
        loggedInUser.user.role.toLowerCase() !== "admin"
      ) {
        alert("Unauthorized!");
        window.location.href = "/auth/login";
        return;
      }
      const { approveDisapprove, page } = this.state;
      const userService = new UserService();
      const reservations = await userService.getEventReservations(
        1,
        this.state.search
      );
      if (reservations && reservations.data.length === 10) {
        this.setState((prevState) => {
          return {
            ...prevState,
            page: 1,
          };
        });
      }
      if (reservations && reservations.data) {
        this.setState({
          reservations: reservations,
        });
      }
    }
  }

  selectTable = async (id) => {
    try {
      const userService = new UserService();
      const reservation = await userService.getReservation(id);
      if (reservation && reservation.data && reservation.data.data) {
        this.setState({ reservation: reservation.data.data, showTable: true });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Sesioni juaj ka skaduar, ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        alert("Something went wrong!");
      }
    }
  };

  approve = async (id, message) => {
    try {
      const { activePage, activeFilter, activeFilterStatus } = this.state;
      const userService = new UserService();
      await userService.approveReservation(id, message);
      let reservations = [];
      if (activeFilter === "1" || activeFilter === "2") {
        reservations = await userService.getReservationsByZone(
          activePage,
          activeFilter
        );
        this.setState({ reservations });
      } else if (
        activeFilterStatus === "pending" ||
        activeFilterStatus === "confirmed" ||
        activeFilterStatus === "rejected"
      ) {
        reservations = await userService.getEventReservationsByStatus(
          activePage,
          activeFilterStatus
        );
        this.setState({ reservations });
      } else {
        reservations = await userService.getEventReservations(
          activePage,
          this.state.search
        );
      }

      if (reservations && reservations.data) {
        this.setState({
          reservations: reservations,
        });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Sesioni juaj ka skaduar, ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        alert("Something went wrong!");
      }
    }
  };

  viewDetails = async (id, type) => {
    const userService = new UserService();
    const reservation = await userService.getReservation(id);
    if (reservation && reservation.data && reservation.data.data) {
      if (type === "details")
        this.setState({
          reservation: reservation.data.data,
          detailsModal: true,
        });

      if (type === "update") {
        const events = await userService.getComingEvents();

        this.setState({
          reservation: reservation.data.data,
          events: events.data,
          reservationType: reservation.data.data.isEvent,
          reservationHour: reservation.data.data.hour,
          eventId: reservation.data.data.eventId,
          updateModal: true,
        });
      }
    }
  };

  viewMessageModal = async (id, modalType) => {
    const userService = new UserService();
    const reservation = await userService.getReservation(id);
    if (reservation && reservation.data && reservation.data.data) {
      this.setState({ reservation: reservation.data.data, messageModal: true });
    }
    if (modalType === "aprovo") {
      this.setState({ modalType: "aprovo" });
    }
    if (modalType === "refuzo") {
      this.setState({ modalType: "refuzo" });
    }
  };

  onMessageChecked = async (event) => {
    this.setState({ messageValue: event.target.value });
  };
  onMessageChange = async (event) => {
    this.setState({ messageValueOther: event.target.value });
  };

  pageClick = async (page) => {
    const userService = new UserService();
    const { activeFilter, activeFilterStatus } = this.state;
    let reservationsPages = [];
    if (activeFilter === "1" || activeFilter === "2") {
      reservationsPages = await userService.getReservationsByZone(
        page,
        activeFilter
      );
    } else if (
      activeFilterStatus === "pending" ||
      activeFilterStatus === "confirmed" ||
      activeFilterStatus === "rejected"
    ) {
      reservationsPages = await userService.getEventReservationsByStatus(
        page,
        activeFilterStatus
      );
    } else {
      reservationsPages = await userService.getEventReservations(
        page,
        this.state.search
      );
    }
    if (reservationsPages.data.length) {
      this.setState({
        reservations: reservationsPages,
        activePage: page,
      });
    }
  };

  reject = async (id, message) => {
    try {
      const { activePage, activeFilter, activeFilterStatus } = this.state;
      const userService = new UserService();
      await userService.rejectReservation(id, message);
      let reservations = [];
      if (activeFilter === "1" || activeFilter === "2") {
        reservations = await userService.getReservationsByZone(
          activePage,
          activeFilter
        );
        this.setState({ reservations });
      } else if (
        activeFilterStatus === "pending" ||
        activeFilterStatus === "confirmed" ||
        activeFilterStatus === "rejected"
      ) {
        reservations = await userService.getEventReservationsByStatus(
          activePage,
          activeFilterStatus
        );
        this.setState({ reservations });
      } else {
        reservations = await userService.getEventReservations(
          activePage,
          this.state.search
        );
      }

      if (reservations && reservations.data) {
        this.setState({
          reservations: reservations,
        });
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Sesioni juaj ka skaduar, ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        alert("Something went wrong!");
      }
    }
  };

  toggle = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        detailsModal: !prevState.detailsModal,
      };
    });

  toggleUpdateModal = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        updateModal: !prevState.updateModal,
      };
    });

  updateReservation = async (id) => {
    const userService = new UserService();

    const isTrue =
      this.state.reservationType === "true" ||
      this.state.reservationType === true;

    await userService.updateReservation(id, {
      isEvent: isTrue ? true : false,
      hour: this.state.reservationHour,
      eventId: isTrue ? this.state.eventId : null,
    });

    const reservations = await userService.getEventReservations(
      1,
      this.state.search
    );

    this.setState({ reservations, activePage: 1, page: 1, updateModal: false });
  };

  toggleMessageModal = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        messageModal: !prevState.messageModal,
        messageValue: "",
        messageValueOther: "",
      };
    });

  setShowTable = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        showTable: !prevState.showTable,
      };
    });

  updateFilter = async (e) => {
    const value = e.target.value;
    this.setState({ activeFilter: value });
    const userService = new UserService();
    if (value === "1" || value === "2") {
      const reservations = await userService.getEventReservationsByZone(
        1,
        value
      );
      this.setState({
        reservations,
        activePage: 1,
        page: 1,
        activeFilterStatus: "all",
        search: "",
      });
    } else {
      const reservations = await userService.getEventReservations(
        1,
        this.state.search
      );
      this.setState({ reservations, activePage: 1, page: 1 });
    }
  };

  updateFilterStatus = async (e) => {
    const value = e.target.value;
    this.setState({ activeFilterStatus: value });
    const userService = new UserService();
    if (value === "pending" || value === "confirmed" || value === "rejected") {
      const reservations = await userService.getEventReservationsByStatus(
        1,
        value
      );
      this.setState({
        reservations,
        activePage: 1,
        page: 1,
        activeFilter: "all",
        search: "",
      });
    } else {
      const reservations = await userService.getEventReservations(
        1,
        this.state.search
      );
      this.setState({ reservations, activePage: 1, page: 1 });
    }
  };

  searchReservations = async (page, value) => {
    this.setState({ search: value });
    const userService = new UserService();
    const reservations = await userService.getEventReservations(page, value);
    if (reservations.data.length) {
      this.setState({ reservations });
    }
  };

  render() {
    const {
      reservations,
      page,
      activePage,
      detailsModal,
      updateModal,
      reservation,
      activeFilter,
      activeFilterStatus,
      messageModal,
      modalType,
      messageValue,
      messageValueOther,
      showTable,
      reservationType,
      reservationHour,
      events,
      eventId,
    } = this.state;

    const userToken = localStorage.getItem("token");
    const loggedInUser = decode(userToken);

    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Rezervimet për Evente</h3>
                  <Row className="d-flex justify-content-between">
                    <Col>
                      <FormGroup className="d-flex flex-direction-row">
                        {loggedInUser.user.restaurant === "" && (
                          <div>
                            <label>Lokacioni</label>
                            <Input
                              onChange={(e) => this.updateFilter(e)}
                              style={{
                                marginTop: 20,
                                width: "200px",
                                display: "block",
                                fontSize: "16px",
                                fontFamily: "sans-serif",
                                fontWeight: "700",
                                color: "#444",
                                lineHeight: "1.3",
                                padding: ".6em 1.4em .5em .8em",
                                boxSizing: "border-box",
                                margin: 0,
                                border: "1px solid #aaa",
                                boxShadow: "0 1px 0 1px rgba(0,0,0,.04)",
                                borderRadius: ".5em",
                                mozAppearance: "none",
                                webkitAppearance: "none",
                                appearance: "none",
                                backgroundColor: "#fff",
                                backgroundImage:
                                  "url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%)",
                                backgroundRepeat: "no-repeat, repeat",
                                backgroundPosition: "right .7em top 50%, 0 0",
                                backgroundSize: ".65em auto, 100%",
                                marginRight: "20px",
                              }}
                              type="select"
                              name="select"
                              id="exampleSelect"
                              className="select-res"
                            >
                              <option
                                selected={activeFilter === "all"}
                                value={"all"}
                              >
                                Të gjitha
                              </option>
                              <option value={"1"}>Rruga B</option>
                              <option value="2">Qender</option>
                            </Input>
                          </div>
                        )}
                        <div>
                          <label>Statusi</label>
                          <Input
                            onChange={(e) => this.updateFilterStatus(e)}
                            style={{
                              marginTop: 20,
                              width: "200px",
                              display: "block",
                              fontSize: "16px",
                              fontFamily: "sans-serif",
                              fontWeight: "700",
                              color: "#444",
                              lineHeight: "1.3",
                              padding: ".6em 1.4em .5em .8em",
                              boxSizing: "border-box",
                              margin: 0,
                              border: "1px solid #aaa",
                              boxShadow: "0 1px 0 1px rgba(0,0,0,.04)",
                              borderRadius: ".5em",
                              mozAppearance: "none",
                              webkitAppearance: "none",
                              appearance: "none",
                              backgroundColor: "#fff",
                              backgroundImage:
                                "url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%)",
                              backgroundRepeat: "no-repeat, repeat",
                              backgroundPosition: "right .7em top 50%, 0 0",
                              backgroundSize: ".65em auto, 100%",
                            }}
                            type="select"
                            name="select-status"
                            id="exampleSelect"
                            className="select-res"
                          >
                            <option
                              selected={activeFilterStatus === "all"}
                              value={"all"}
                            >
                              Të gjitha
                            </option>
                            <option value={"pending"}>Në Pritje</option>
                            <option value="confirmed">Konfirmuar</option>
                            <option value="rejected">Refuzuar</option>
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col className="col-12 col-md-12 col-lg-4">
                      <label> {"\u00A0"} </label>
                      <FormGroup>
                        <DelayInput
                          element={Input}
                          minLength={1}
                          delayTimeout={500}
                          placeholder="Kërko"
                          value={this.state.search}
                          onChange={(e) => {
                            this.searchReservations(1, e.target.value);
                            this.setState({ activePage: 1 });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Aksion</th>
                      <th scope="col">Emri Mbiemri</th>
                      <th scope="col">Tavolina</th>
                      <th scope="col">Nr. Tel</th>
                      <th scope="col">Restauranti</th>
                      <th scope="col">Statusi</th>
                      <th scope="col">Persona</th>
                      <th scope="col">Rezervuar për daten</th>
                      <th scope="col">Shënim</th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {reservations &&
                      reservations.data &&
                      reservations.data.length &&
                      reservations.data.map((reservation) => (
                        <tr key={reservation.id}>
                          <td className="text-left">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                {reservation.status === "pending" && (
                                  <>
                                    <DropdownItem
                                      href="#"
                                      onClick={() =>
                                        this.selectTable(reservation.id)
                                      }
                                    >
                                      Cakto tavolinen
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#"
                                      onClick={() =>
                                        this.viewMessageModal(
                                          reservation.id,
                                          "refuzo"
                                        )
                                      }
                                    >
                                      Refuzo
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#"
                                      onClick={(e) =>
                                        this.viewDetails(
                                          reservation.id,
                                          "update"
                                        )
                                      }
                                    >
                                      Edito
                                    </DropdownItem>
                                  </>
                                )}
                                <DropdownItem
                                  href="#"
                                  onClick={(e) =>
                                    this.viewDetails(reservation.id, "details")
                                  }
                                >
                                  Detajet
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {reservation.name || "Ska Emer"}
                            </span>
                          </th>
                          <td>{reservation.table || "-"}</td>
                          <td>{reservation.phone || "Ska Nr Tel."}</td>
                          <td>
                            {reservation.restaurant === "1"
                              ? "Rruga B"
                              : "Qender"}
                          </td>
                          <td>
                            {reservation.status === "pending" ? (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"warning"}
                              >
                                NË PRITJE
                              </Alert>
                            ) : reservation.status === "confirmed" ? (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"success"}
                              >
                                KONFIRMUAR
                              </Alert>
                            ) : (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"danger"}
                              >
                                REFUZUAR
                              </Alert>
                            )}
                            {/*</Badge>*/}
                          </td>

                          <td>{reservation.guests || "Pa caktuar"}</td>
                          <td>
                            {moment(reservation.date).format("DD/MM/YYYY")} ·{" "}
                            {reservation && reservation.hour.substring(0, 5)}
                          </td>
                          <td>{reservation.note || "Ska shenim"}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {Array.apply(null, { length: activePage + 1 }).map(
                        (p, index) => {
                          return (
                            <PaginationItem
                              key={index}
                              className={
                                index + 1 === activePage ? "active" : ""
                              }
                            >
                              <PaginationLink
                                href="#"
                                onClick={(e) => this.pageClick(index + 1)}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        }
                      )}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <div>
          <Modal isOpen={detailsModal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>
              Detajet e rezervimit ID: {reservation ? reservation.id : null}
            </ModalHeader>
            <ModalBody>
              <p>
                <b>Rezervuar për daten: </b>
                {reservation &&
                  moment(reservation.date).format("DD/MM/YYYY")} ·{" "}
                {reservation && reservation.hour}
              </p>
              <p>
                <b>Përshkrimi:</b> {reservation && reservation.note}
              </p>
              <p>
                <b>Restauranti:</b>
                {reservation &&
                  reservation.restaurant &&
                  (reservation.restaurant === "1" ? " Rruga B" : " Qender")}
              </p>
              <p>
                <b>Statusi:</b>
                {reservation &&
                  reservation.status &&
                  (reservation.status === "rejected"
                    ? " Refuzuar"
                    : reservation.status === "confirmed"
                    ? " Konfirmuar"
                    : " Në pritje")}
              </p>
              <p>
                <b>Persona:</b> {reservation && reservation.guests}
              </p>
              <p>
                <b>Data e krijimit: </b>
                {(reservation &&
                  moment(reservation && reservation.createdAt).format(
                    "DD/MM/YYYY · HH:mm"
                  )) ||
                  "-"}
              </p>
              {reservation && reservation.messageForClient && (
                <p>
                  <b>Mesazhi:</b> {reservation && reservation.messageForClient}
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Mbyll
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </div>

        <div>
          <Modal
            isOpen={updateModal}
            toggleUpdateModal={this.toggleUpdateModal}
          >
            <ModalHeader
              toggle={this.toggleUpdateModal}
              className="absoluteRight"
            ></ModalHeader>
            <ModalBody>
              <Form role="form">
                <FormGroup>
                  <h3>{reservation ? reservation.name : ""}</h3>
                </FormGroup>
                <FormGroup>
                  <Label for="status">Tipi i rezervimit:</Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      type="select"
                      value={reservationType}
                      onChange={(e) =>
                        this.setState({
                          reservationType: e.target.value,
                        })
                      }
                      name="select"
                      id="status"
                    >
                      <option value={false}>Rezervim i thjeshtë</option>
                      <option value={true}>Rezervim për event</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
                {(reservationType === true || reservationType === "true") && (
                  <FormGroup>
                    <Label for="status">Eventi:</Label>
                    <InputGroup className="input-group-alternative mb-3">
                      <Input
                        type="select"
                        onChange={(e) =>
                          this.setState({ eventId: e.target.value })
                        }
                        name="select"
                        id="status"
                      >
                        <option value={""}>Zgjidhni eventin</option>
                        {events.map((event) => {
                          return (
                            <option
                              key={event.id}
                              value={event.id}
                              selected={eventId === event.id ? true : false}
                            >
                              {event.title}
                            </option>
                          );
                        })}
                      </Input>
                    </InputGroup>
                  </FormGroup>
                )}
                <FormGroup className="flex-direction-row">
                  <Label for="time">Ora:</Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Ora"
                      id={"time"}
                      onChange={(e) =>
                        this.setState({ reservationHour: e.target.value })
                      }
                      type="time"
                      value={reservationHour.substring(0, 5)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => this.updateReservation(reservation.id)}
              >
                Ruaj
              </Button>
              <Button color="primary" onClick={this.toggleUpdateModal}>
                Mbyll
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div>
          <Modal isOpen={messageModal} toggle={this.toggleMessageModal}>
            <ModalHeader toggle={this.toggleMessageModal}>
              Rezervimi me ID: {reservation ? reservation.id : null}
            </ModalHeader>
            <ModalBody>
              {modalType === "aprovo" && <p>Mesazhi i aprovimit</p>}
              {modalType === "refuzo" && <p>Mesazhi i refuzimit</p>}

              <Form role="form">
                <FormGroup className="messages" tag="fieldset">
                  {modalType === "aprovo" &&
                    Object.entries(approveMessages).map(([key, value]) => (
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="radio1"
                            value={key}
                            onChange={this.onMessageChecked}
                          />
                          {value}
                        </Label>
                      </FormGroup>
                    ))}
                  {modalType === "refuzo" &&
                    Object.entries(rejectMessages).map(([key, value]) => (
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="radio2"
                            value={key}
                            onChange={this.onMessageChecked}
                          />
                          {value}
                        </Label>
                      </FormGroup>
                    ))}
                </FormGroup>
                {messageValue === "tjeter" && (
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <Input
                        placeholder="Shkruaj mesazhin"
                        type="text"
                        onChange={this.onMessageChange}
                        // value={reservation?.name}
                      />
                    </InputGroup>
                  </FormGroup>
                )}
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  if (modalType === "aprovo") {
                    let message = messageValueOther
                      ? messageValueOther
                      : approveMessages[messageValue];
                    message =
                      message === undefined || message === "Tjeter"
                        ? ""
                        : message;
                    this.approve(reservation.id, message);
                  }
                  if (modalType === "refuzo") {
                    let message = messageValueOther
                      ? messageValueOther
                      : rejectMessages[messageValue];
                    message =
                      message === undefined || message === "Tjeter"
                        ? ""
                        : message;
                    this.reject(reservation.id, message);
                  }
                  this.toggleMessageModal();
                }}
              >
                Konfirmo
              </Button>{" "}
              <Button color="primary" onClick={this.toggleMessageModal}>
                Mbyll
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </div>
        {showTable && (
          <ShowTables
            showTable={showTable}
            setShowTable={this.setShowTable}
            reservation={reservation}
            showBothReservations={false}
            fromEvent={true}
          />
        )}
      </>
    );
  }
}

export default EventReservations;
