/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import { DelayInput } from "react-delay-input";
// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import { UserService } from "../UserService";
import decode from "jwt-decode";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: null,
      user: null,
      page: 1,
      activePage: 1,
      detailsModal: false,
      giveAwardModal: false,
      clickedNotification: null,
      points: 0,
      description: null,
      search: "",
      totalUsers: 0,
    };
  }

  async componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
    await this.fetchUsers();
  }

  fetchUsers = async () => {
    const userService = new UserService();
    const users = await userService.getUsers(
      this.state.activePage,
      this.state.search
    );
    console.log(users);
    if (users && users.data.length === 10) {
      this.setState((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        };
      });
    }
    if (users && users.data) {
      this.setState({
        users: users,
      });
    }
    if (users && users.totalUsers) {
      this.setState({
        totalUsers: users.totalUsers,
      });
    }
  };

  pageClick = async (page) => {
    const userService = new UserService();
    const reservationsPages = await userService.getUsers(
      page,
      this.state.search
    );
    if (reservationsPages.data.length) {
      this.setState({
        users: reservationsPages,
        activePage: page,
      });
    }
  };

  searchUsers = async (page, value) => {
    this.setState({ search: value });
    const userService = new UserService();
    const users = await userService.getUsers(page, value);
    if (users.data.length) {
      this.setState({ users });
    }
  };

  viewDetails = async (id) => {
    const userService = new UserService();
    const userDetails = await userService.getUserAward(id);
    if (userDetails && userDetails.data && userDetails.data.data) {
      this.setState({ userDetails: userDetails.data.data, detailsModal: true });
    }
  };

  toggle = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        detailsModal: !prevState.detailsModal,
      };
    });
  toggleAwardModal = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        giveAwardModal: !prevState.giveAwardModal,
        points: 0,
        description: null,
      };
    });
  readNotification = async (id) => {
    const userService = new UserService();
    await userService.readNotification(id);
    this.setState({ clickedNotification: id });
  };

  render() {
    const { users, activePage, detailsModal, userDetails, totalUsers } =
      this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex justify-content-between">
                  <h3 className="mb-0">Përdoruesit ({totalUsers})</h3>
                  <FormGroup>
                    <DelayInput
                      element={Input}
                      minLength={1}
                      delayTimeout={500}
                      placeholder="Kërko"
                      onChange={(e) => {
                        this.searchUsers(1, e.target.value);
                        this.setState({ activePage: 1 });
                      }}
                    />
                    {/* <Input
                      type="text"
                      name="search-top"
                      placeholder="Kërko"
                      onChange={(e) =>
                        this.searchUsers(activePage, e.target.value)
                      }
                    /> */}
                  </FormGroup>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Emri dhe mbiemri</th>
                      <th scope="col">Email</th>
                      <th scope="col">Nr. Tel</th>
                      <th scope="col">Pikët</th>
                      <th scope="col">Aksion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users &&
                      users.data &&
                      users.data.length &&
                      users.data.map((user) => (
                        <tr key={user._id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">{user.name}</span>
                          </th>
                          <td>{user.email || "N/A"}</td>
                          <td>{user.phone || "N/A"}</td>
                          <td>{user.awardPoints}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => this.viewDetails(user._id)}
                                >
                                  Detajet
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {Array.apply(null, { length: activePage + 1 }).map(
                        (p, index) => {
                          return (
                            <PaginationItem
                              key={index}
                              className={
                                index + 1 === activePage ? "active" : ""
                              }
                            >
                              <PaginationLink
                                href="#"
                                onClick={(e) => this.pageClick(index + 1)}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        }
                      )}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal isOpen={detailsModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <h2>Detajet për përdoruesin</h2>
          </ModalHeader>
          <ModalBody>
            <p style={{ marginTop: "30px" }}>
              <b>ID:</b> {userDetails && userDetails._id}
            </p>
            <p>
              <b>Emri dhe mbiemri:</b> {userDetails && userDetails.name}
            </p>
            <p>
              <b>Pikët:</b>
              {userDetails &&
                `${userDetails.awardPoints} ( ${(
                  userDetails.awardPoints / 200
                ).toFixed(2)}€ )`}
            </p>
            <p>
              <b>Email:</b> {userDetails && userDetails.email}
            </p>
            <p>
              <b>Nr. Tel.:</b> {userDetails && userDetails.phone}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Mbyll
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Users;
