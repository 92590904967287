/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Label,
  Col,
  Spinner,
} from "reactstrap";
import { UserService } from "../../UserService";
import decode from "jwt-decode";
import { post } from "axios";

class NewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant: "1",
      title: "",
      description: "",
      date: "",
      hour: "",
      status: "coming",
      imageCover: null,
      notifyAll: false,
      isLoading: false,
    };
  }

  fileUpload = async (e) => {
    console.log("event", e.target.value);
    const formData = new FormData();
    let data = e.target.files[0];
    console.log("data", data);
    formData.append("image", data);
    this.setState({ imageCover: formData });
    // console.log("fd", formData.config)
    // return
  };

  componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin" &&
      loggedInUser.user.role.toLowerCase() !== "author"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
  }

  createEvent = async () => {
    const {
      restaurant,
      title,
      description,
      date,
      hour,
      status,
      imageCover,
      notifyAll,
    } = this.state;
    if (!restaurant.trim()) {
      alert("Restauranti është fushë e detyruar");
      return;
    }
    if (!title.trim()) {
      alert("Titulli është fushë e detyruar");
      return;
    }
    if (!description.trim()) {
      alert("Përshkrimi është fushë e detyruar");
      return;
    }
    if (!date.trim()) {
      alert("Data është fushë e detyruar");
      return;
    }
    if (!hour.trim()) {
      alert("Ora është fushë e detyruar");
      return;
    }
    if (!imageCover) {
      alert("Foto është fushë e detyruar");
      return;
    }
    if (!status.trim()) {
      alert("Statusi është fushë e detyruar");
      return;
    }
    try {
      this.setState({ isLoading: true });
      const userService = new UserService();
      const user = await userService.createEvent({
        restaurant,
        title,
        description,
        date,
        hour,
        status,
        notifyAll,
      });

      if (user && user.data && user.data.data && user.data.data.id) {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const url = `https://api.izisapartment197.com/api/user/upload/${user.data.data.id}`;
        const photoUpload = await post(url, this.state.imageCover, config);
        if (
          photoUpload &&
          photoUpload.data &&
          photoUpload.data &&
          photoUpload.data.filename
        ) {
          await userService.updateEventEvent(
            { imagePath: photoUpload.data.filename },
            user.data.data.id
          );
        }
      }
      this.setState({ isLoading: false });
      window.location.href = "/admin/events";
    } catch (e) {
      this.setState({ isLoading: false });
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        console.log("error", e.response);
        alert("Something went wrong!");
      }
    }
  };
  render() {
    return (
      <>
        <Col lg="6" md="8" className={"center mt-5"}>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h3>Krijo një event të ri</h3>
              </div>
              <Form role="form">
                <FormGroup>
                  <FormGroup>
                    <Label for="status">Restauranti:</Label>
                    <InputGroup className="input-group-alternative mb-3">
                      <Input
                        type="select"
                        onChange={(e) =>
                          this.setState({ restaurant: e.target.value })
                        }
                        name="select"
                        id="status"
                      >
                        <option value={"1"}>Rruga B</option>
                        <option value={"2"}>Qender</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Titulli"
                      type="text"
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Përshkrim"
                      max={3000}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      type="textarea"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="data">Data:</Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Data"
                      id="data"
                      onChange={(e) => {
                        if (e.target.value) {
                          this.setState({
                            date: new Date(e.target.value || "").toISOString(),
                          });
                        }
                      }}
                      type="date"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <Label for="time">Ora:</Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Ora"
                      id={"time"}
                      onChange={(e) => this.setState({ hour: e.target.value })}
                      type="time"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="exampleFile">Foto</Label>
                  <Input
                    onChange={this.fileUpload}
                    type="file"
                    name="file"
                    id="exampleFile"
                  />
                </FormGroup>
                <FormGroup className={"ml-3"}>
                  <Label check>
                    <InputGroup className="input-group-alternative mb-3">
                      <Input
                        onChange={(e) =>
                          this.setState({ notifyAll: e.target.checked })
                        }
                        type="checkbox"
                      />
                      Njofto të gjithë përdoruesit
                    </InputGroup>
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label for="status">Statusi:</Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input type="select" disabled name="select" id="status">
                      <option selected value={"coming"}>
                        I ardhshëm
                      </option>
                    </Input>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={this.createEvent}
                    className="mt-4"
                    color="primary"
                    type="button"
                    disabled={this.state.isLoading && true}
                  >
                    Krijo eventin
                    {this.state.isLoading && (
                      <Spinner size={"sm"} animation="border" />
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default NewEvent;
