/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Col,
} from "reactstrap";
import { UserService } from "../../UserService";
import decode from "jwt-decode";

class NewAnnouncements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
  }

  createEvent = async () => {
    const { title, description } = this.state;

    if (!title.trim()) {
      alert("Titulli është fushë e detyruar");
      return;
    }
    if (!description.trim()) {
      alert("Përshkrimi është fushë e detyruar");
      return;
    }

    try {
      this.setState({ isLoading: true });
      const userService = new UserService();
      await userService.createAnnouncement({ title, description });
      window.location.href = "/admin/index";
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        console.log("error", e.response);
        alert("Something went wrong!");
      }
    }
  };
  render() {
    return (
      <>
        <Col lg="6" md="8" className={"center mt-5"}>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h3>Krijo një njoftim të ri</h3>
                <small>Këtë njoftim do ta pranojnë të gjithë përdoruesit</small>
              </div>
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Titulli"
                      type="text"
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Përshkrim"
                      max={3000}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      type="textarea"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={this.createEvent}
                    className="mt-4"
                    color="primary"
                    type="button"
                    disabled={this.state.isLoading && true}
                  >
                    Dërgo
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default NewAnnouncements;
