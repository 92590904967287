/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "../src/views/Index.js";
import NewEvent from "../src/views/examples/NewEvent.js";
import Login from "../src/views/examples/Login.js";
import Reservations from "../src/views/Reservations";
import Events from "../src/views/Events";
import Notifications from "../src/views/Notifications";
import EventReservations from "../src/views/EventReservations";
import Suggestions from "./views/Suggestions";
import ResetPassword from "./views/ResetPassword";
import EditEvent from "./views/EditEvent";
import Announcements from "./views/Announcements";
import NewAnnouncements from "./views/examples/NewAnnouncements";
import News from "./views/News";
import NewNews from "./views/examples/NewNews";
import QRCodes from "./views/QRCodes";
import Awards from "./views/Awards";
import AwardsHistory from "./views/AwardsHistory";
import Users from "./views/Users";

var routes = [
  {
    path: "/login",
    name: "",
    icon: "",
    disable: true,
    component: Login,
    layout: "/auth",
  },
  {
    path: "/reset-password",
    name: "",
    icon: "",
    disable: true,
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/reservations",
    name: "Rezervimet",
    icon: "ni ni-notification-70",
    component: Reservations,
    layout: "/admin",
    count: "totalOtherReservations",
  },
  {
    path: "/events-reservations",
    name: "Rezervimet [Event]",
    icon: "ni ni-calendar-grid-58",
    component: EventReservations,
    layout: "/admin",
    count: "totalEventReservations",
  },
  {
    path: "/events",
    name: "Eventet [Lista]",
    icon: "ni ni-calendar-grid-58",
    component: Events,
    layout: "/admin",
    author: true,
  },
  {
    path: "/new-event",
    name: "Krijo event",
    icon: "ni ni-calendar-grid-58",
    component: NewEvent,
    layout: "/admin",
    author: true,
  },
  {
    path: "/edit-event/:eventId",
    component: EditEvent,
    disable: true,
    layout: "/admin",
    author: true,
  },
  {
    path: "/awards",
    name: "Dhuro shpërblime",
    icon: "ni ni-bag-17",
    component: Awards,
    layout: "/admin",
  },
  {
    path: "/awards-history",
    name: "Historia e shpërblimeve",
    icon: "ni ni-bag-17",
    component: AwardsHistory,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: `Njoftimet`,
    icon: "ni ni-bell-55",
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/announcements",
    name: "Njoftimet[Të përgjithshme]",
    icon: "ni ni-bell-55",
    component: Announcements,
    layout: "/admin",
  },
  {
    path: "/add-announcement",
    name: "Dërgo njoftim [Të përgjithshme]",
    icon: "ni ni-bell-55",
    component: NewAnnouncements,
    layout: "/admin",
  },
  {
    path: "/news",
    name: "Lajmet",
    icon: "ni ni-single-copy-04",
    component: News,
    layout: "/admin",
    author: true,
  },
  {
    path: "/new-news",
    name: "Krijo lajm",
    icon: "ni ni-single-copy-04",
    component: NewNews,
    layout: "/admin",
    author: true,
  },
  {
    path: "/qr-codes",
    name: "Kodet QR",
    icon: "ni ni-key-25",
    component: QRCodes,
    layout: "/admin",
  },
  {
    path: "/suggestions",
    name: "Sygjerimet",
    icon: "ni ni-like-2",
    component: Suggestions,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Përdoruesit",
    icon: "ni ni-single-02",
    component: Users,
    layout: "/admin",
  },
];

export default routes;
