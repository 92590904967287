import React, { useState, useEffect } from "react";
import * as axios from "axios";
import moment from "moment";
import { UserService } from "../../UserService";

// reactstrap components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
} from "reactstrap";

const approveMessages = {
  val1: "Faleminderit që na kontaktuat për rezervim! Rezervimi u aprovua, ju mirëpresim!",
  tjeter: "Tjetër",
};

const TableModal = ({
  toggleMainModalRender,
  tableModalOpen,
  toggleTableModal,
  id,
  restaurant,
  isEvent,
  date,
  reservation,
}) => {
  const [messageModal, setMessageModal] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [messageValueOther, setMessageValueOther] = useState("");

  const toggleMessageModal = () => {
    setMessageModal((prevModal) => !prevModal);
    setMessageValue("");
    setMessageValueOther("");
  };

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [hour, setHour] = useState("");
  const [guests, setGuests] = useState(4);
  const [note, setNote] = useState("");
  const [newReservation, setNewReservation] = useState(false);

  const confirmAddTable = () => {
    if (newReservation) {
      approve();
      toggleTableModal();
    } else {
      setMessageModal(true);
    }
  };

  const approve = async () => {
    try {
      const data = {
        restaurant,
        isEvent,
        newReservation,
        tableId: id,
        date,
        reservation: {
          name,
          email,
          phone,
          hour,
          guests,
          note,
          date,
        },
      };

      const userService = new UserService();
      const createdTable = await userService.createTable(data);
      if (createdTable !== undefined) {
        toggleMainModalRender();
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Sesioni juaj ka skaduar, ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        alert("Something went wrong!");
      }
    }
  };

  const approveMessage = async (message) => {
    try {
      const data = {
        restaurant,
        isEvent,
        reservationId: reservation.id,
        tableId: id,
        date: reservation.date,
        messageForClient: message,
      };
      const userService = new UserService();
      const createdTable = await userService.createTable(data);
      if (createdTable !== undefined) {
        toggleMainModalRender();
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Sesioni juaj ka skaduar, ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        alert("Something went wrong!");
      }
    }
  };

  useEffect(() => {
    if (reservation) {
      setNewReservation(false);
    } else {
      setNewReservation(true);
    }
  }, []);

  return (
    <>
      <Modal isOpen={tableModalOpen} toggle={toggleTableModal}>
        <ModalHeader toggle={toggleTableModal}>
          <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>{id}</p>
        </ModalHeader>
        <ModalBody>
          <Form role="form">
            <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
                <Input
                  placeholder="Emri Mbiemri"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={reservation?.name}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
                <Input
                  placeholder="Telefoni"
                  onChange={(e) => setPhone(e.target.value)}
                  type="text"
                  value={reservation?.phone}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
                <Input
                  placeholder="Emaili"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={reservation?.email}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label for="time">Ora:</Label>
              <InputGroup className="input-group-alternative mb-3">
                <Input
                  placeholder="Ora"
                  id={"time"}
                  onChange={(e) => setHour(e.target.value)}
                  type="time"
                  value={reservation?.hour.substring(0, 5)}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
                <Input
                  placeholder="Nr personave"
                  onChange={(e) => setGuests(e.target.value)}
                  type="number"
                  value={reservation?.guests}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative mb-3">
                <Input
                  placeholder="Shënime shtesë"
                  max={3000}
                  onChange={(e) => setNote(e.target.value)}
                  type="textarea"
                  value={reservation?.note}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={confirmAddTable}>
            Konfirmo
          </Button>{" "}
          <Button color="primary" onClick={toggleTableModal}>
            Mbyll
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Modal isOpen={messageModal} toggle={toggleMessageModal}>
        <ModalHeader toggle={toggleMessageModal}>
          Rezervimi: {reservation ? reservation?.name : null}
        </ModalHeader>
        <ModalBody>
          <p>Mesazhi i aprovimit</p>
          <Form role="form">
            <FormGroup className="messages" tag="fieldset">
              {Object.entries(approveMessages).map(([key, value]) => (
                <FormGroup key={key} check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      value={key}
                      onChange={(e) => setMessageValue(e.target.value)}
                    />{" "}
                    {value}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            {messageValue === "tjeter" && (
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <Input
                    placeholder="Shkruaj mesazhin"
                    type="text"
                    onChange={(e) => setMessageValueOther(e.target.value)}
                    // value={reservation?.name}
                  />
                </InputGroup>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              let message = messageValueOther
                ? messageValueOther
                : approveMessages[messageValue];
              message =
                message === undefined || message === "Tjeter" ? "" : message;
              approveMessage(message);

              toggleMessageModal();
              toggleTableModal();
            }}
          >
            Konfirmo
          </Button>{" "}
          <Button color="primary" onClick={toggleMessageModal}>
            Mbyll
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TableModal;
