import React, { useState, useEffect } from "react";
import * as axios from "axios";

import { UserService } from "../../UserService";
import ConfirmModal from "./ConfirmModal";

// reactstrap components
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  Collapse,
  CardBody,
} from "reactstrap";

const ListInTable = ({
  toggleMainModalRender,
  listTableModalOpen,
  toggleListTableModalOpen,
  id,
  restaurant,
  isEvent,
  date,
}) => {
  const [collapse, setCollapse] = useState(0);
  const [tableList, setTableList] = useState([]);
  const toggle = (e) => {
    let event = e.target.dataset.event;
    setCollapse(collapse === Number(event) ? 0 : Number(event));
  };

  useEffect(() => {
    const fetchTable = async () => {
      const userService = new UserService();
      const tables = await userService.getOneTable(
        date,
        restaurant,
        isEvent,
        id
      );
      setTableList(tables.data);
    };
    fetchTable();
  }, []);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const toggleConfirmModal = () => {
    setConfirmModalOpen((prevModal) => !prevModal);
  };
  const [confirmText, setConfirmText] = useState("");
  const [actionType, setActionType] = useState("");
  const [tableCreateId, setTableCreateId] = useState("");

  return (
    <>
      <Modal isOpen={listTableModalOpen} toggle={toggleListTableModalOpen}>
        <ModalHeader toggle={toggleListTableModalOpen}>
          <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>{id}</p>
        </ModalHeader>
        <ModalBody>
          {tableList.map((list, index) => {
            return (
              <Card style={{ marginBottom: "1rem" }} key={index + 1}>
                <CardHeader
                  style={{ display: "flex", justifyContent: "space-between" }}
                  data-event={index + 1}
                  onClick={toggle}
                  className={
                    list?.status === "reserved"
                      ? "cardHeader reserved"
                      : "cardHeader confirmed"
                  }
                >
                  <span
                    data-event={index + 1}
                    onClick={toggle}
                    style={{ width: "40%" }}
                  >
                    {list?.reservation[0]?.name}
                  </span>

                  <span
                    data-event={index + 1}
                    onClick={toggle}
                    style={{ width: "20%", textAlign: "center" }}
                  >
                    {list?.reservation[0]?.hour
                      ? (list?.reservation[0]?.hour).substring(0, 5)
                      : list?.reservation[0]?.hour}
                  </span>

                  {list?.status === "reserved" && (
                    <>
                      <span
                        style={{
                          width: "20%",
                          textAlign: "center",
                          fontSize: "22px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setConfirmModalOpen(true);
                          setConfirmText(
                            "Konfirmo ta largoni klientin nga tavolina?"
                          );
                          setActionType("delete");
                          setTableCreateId(list?._id);
                        }}
                      >
                        <i className="ni ni-fat-remove" />
                      </span>
                      <span
                        style={{
                          width: "20%",
                          textAlign: "center",
                          fontSize: "22px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setConfirmModalOpen(true);
                          setConfirmText(
                            "Konfirmo që klienti është vendosur në tavolinë?"
                          );
                          setActionType("confirm");
                          setTableCreateId(list?._id);
                        }}
                      >
                        <i className="ni ni-check-bold" />
                      </span>
                    </>
                  )}
                </CardHeader>
                <Collapse isOpen={collapse === index + 1}>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Persona: {list?.reservation[0]?.guests}</span>
                      <span>Tel: {list?.reservation[0]?.phone}</span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Shënim:{list?.reservation[0]?.note}</span>
                    </div>
                  </CardBody>
                </Collapse>
              </Card>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleListTableModalOpen}>
            Mbyll
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <ConfirmModal
        toggleMainModalRender={toggleMainModalRender}
        confirmModalOpen={confirmModalOpen}
        toggleConfirmModal={toggleConfirmModal}
        toggleListTableModalOpen={toggleListTableModalOpen}
        text={confirmText}
        action={actionType}
        tableCreateId={tableCreateId}
      />
    </>
  );
};
export default ListInTable;
