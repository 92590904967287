/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { DelayInput } from "react-delay-input";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  FormGroup,
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import { UserService } from "../UserService";
import decode from "jwt-decode";

class Awards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: [],
      reservation: null,
      page: 1,
      activePage: 1,
      detailsModal: false,
      giveAwardModal: false,
      clickedNotification: null,
      points: 0,
      moneyPoints: 0,
      description: null,
      search: "",
      isLoading: false,
    };
  }

  async componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
    await this.getUserAwards();
  }

  getUserAwards = async () => {
    const userService = new UserService();
    const reservations = await userService.getAwardList(
      this.state.activePage,
      this.state.search
    );
    if (reservations && reservations.data.length === 10) {
      this.setState((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        };
      });
    }
    if (reservations && reservations.data) {
      this.setState({
        reservations: reservations,
      });
    }
  };

  pageClick = async (page) => {
    const userService = new UserService();
    const reservationsPages = await userService.getAwardList(
      page,
      this.state.search
    );
    if (reservationsPages.data.length) {
      this.setState({
        reservations: reservationsPages,
        activePage: page,
      });
    }
  };

  searchAwardList = async (page, value) => {
    this.setState({ search: value });
    const userService = new UserService();
    const reservationsPages = await userService.getAwardList(page, value);
    if (reservationsPages.data.length) {
      this.setState({ reservations: reservationsPages });
    }
  };

  viewDetails = async (id) => {
    const userService = new UserService();
    const event = await userService.getUserAward(id);
    if (event && event.data && event.data.data) {
      this.setState({ event: event.data.data, detailsModal: true });
    }
  };

  giveAward = async (id) => {
    const userService = new UserService();
    const event = await userService.getUserAward(id);
    if (event && event.data && event.data.data) {
      this.setState({ event: event.data.data, giveAwardModal: true });
    }
  };

  submitGiveAward = async (id) => {
    this.setState({ isLoading: true });

    const userService = new UserService();
    const { points, description } = this.state;
    const event = await userService.giveAward(id, points, description);
    if (!event) {
      this.setState({ isLoading: false });
    }
    if (event && event.data && event.data.data) {
      await this.getUserAwards();
      this.setState({
        event: event.data.data,
        giveAwardModal: false,
        isLoading: false,
      });
    }
  };

  toggle = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        detailsModal: !prevState.detailsModal,
      };
    });
  toggleAwardModal = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        giveAwardModal: !prevState.giveAwardModal,
        points: 0,
        description: null,
      };
    });
  readNotification = async (id) => {
    const userService = new UserService();
    await userService.readNotification(id);
    this.setState({ clickedNotification: id });
  };

  render() {
    const {
      reservations,
      activePage,
      detailsModal,
      event,
      giveAwardModal,
      points,
      moneyPoints,
      description,
    } = this.state;
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0 d-flex justify-content-between">
                  <h3 className="mb-0">Dhuro shpërblime</h3>
                  <FormGroup>
                    <DelayInput
                      element={Input}
                      minLength={1}
                      delayTimeout={500}
                      placeholder="Kërko"
                      onChange={(e) => {
                        this.searchAwardList(1, e.target.value);
                        this.setState({ activePage: 1 });
                      }}
                    />
                    {/* <Input
                      type="text"
                      name="search-top"
                      placeholder="Kërko"
                      onChange={(e) =>
                        this.searchAwardList(activePage, e.target.value)
                      }
                    /> */}
                  </FormGroup>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Emri mbiemri</th>
                      <th scope="col">Pikët</th>
                      <th scope="col">Nr. Tel</th>
                      <th scope="col">Aksion</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {reservations &&
                      reservations.data &&
                      reservations.data.length &&
                      reservations.data.map((reservation) => (
                        <tr key={reservation._id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {reservation.name}
                            </span>
                          </th>
                          <td>{reservation.awardPoints}</td>
                          <td>{reservation.phone || "N/A"}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) =>
                                    this.viewDetails(reservation._id)
                                  }
                                >
                                  Detajet
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) =>
                                    this.giveAward(reservation._id)
                                  }
                                >
                                  Shpërble
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {Array.apply(null, { length: activePage + 1 }).map(
                        (p, index) => {
                          return (
                            <PaginationItem
                              key={index}
                              className={
                                index + 1 === activePage ? "active" : ""
                              }
                            >
                              <PaginationLink
                                href="#"
                                onClick={(e) => this.pageClick(index + 1)}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        }
                      )}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal isOpen={detailsModal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            <h2>Detajet për përdoruesin</h2>
          </ModalHeader>
          <ModalBody>
            <p style={{ marginTop: "30px" }}>
              <b>ID:</b> {event && event._id}
            </p>
            <p>
              <b>Emri plote:</b> {event && event.name}
            </p>
            <p>
              <b>Pikët:</b> {event && event.awardPoints}
            </p>
            <p>
              <b>Email:</b> {event && event.email}
            </p>
            <p>
              <b>Nr. Tel.:</b> {event && event.phone}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Mbyll
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal isOpen={giveAwardModal} toggle={this.toggleAwardModal}>
          <ModalHeader toggle={this.toggleAwardModal}>
            <h2>Shpërble përdoruesin</h2>
          </ModalHeader>
          <ModalBody>
            {/* <p style={{ marginTop: "30px" }}>
              <b>ID:</b> {event && event._id}
            </p> */}
            <p>
              <b>Emri Mbiemri:</b> {event && event.name}
            </p>
            <p>
              <b>Pikët:</b>{" "}
              {event &&
                `${event.awardPoints} ( ${(event.awardPoints / 200).toFixed(
                  2
                )}€ )`}
            </p>
            <p>
              <b>Tel:</b> {event && event.phone}
            </p>
            <p>
              <b>Pikët për shpërblim:</b>
            </p>
            <InputGroup className="input-group-alternative mb-3">
              <Input
                placeholder="Pikët"
                type="number"
                value={points}
                onChange={(e) => {
                  this.setState({ points: e.target.value });
                  this.setState({ moneyPoints: e.target.value / 200 });
                }}
              />
            </InputGroup>
            <p>
              <b>Paratë për shpërblim:</b>
            </p>
            <InputGroup className="input-group-alternative mb-3">
              <Input
                placeholder="Pikët"
                type="number"
                value={moneyPoints}
                onChange={(e) => {
                  this.setState({ points: e.target.value * 200 });
                  this.setState({ moneyPoints: e.target.value });
                }}
              />
            </InputGroup>
            <p>
              <b>Përshkrimi:</b>
            </p>
            <InputGroup className="input-group-alternative mb-3">
              <Input
                placeholder="Përshkrimi"
                type="text"
                value={description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.submitGiveAward(event._id)}
              disabled={this.state.isLoading && true}
            >
              Shpërble
            </Button>{" "}
            <Button color="primary" onClick={this.toggleAwardModal}>
              Mbyll
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Awards;
