/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState, useEffect } from "react";
import * as axios from "axios";

// reactstrap components
import { Button } from "reactstrap";

// import ShowTables from "../Tables/ShowTables";

const Footer = () => {
  // const [showTable, setShowTable] = useState(false);
  return (
    <footer className="footer">
      {/* <Button
        onClick={() => {
          setShowTable(true);
        }}
      >
        Tavolinat
      </Button>

      {showTable && (
        <ShowTables
          showTable={showTable}
          setShowTable={setShowTable}
          reservation={null}
        />
      )} */}
    </footer>
  );
};
export default Footer;
