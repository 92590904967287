import React, { useState, useEffect } from "react";

const SingleTable = ({
  table: { tableId, positionTop, positionLeft, shape, width, height },
  hasListTable,
  addTable,
  listTable,
}) => {
  return (
    <div
      id={tableId}
      className={`singleTable ${shape} ${hasListTable && "reserved"}`}
      style={{
        left: positionLeft,
        top: positionTop,
        width: width,
        height: height,
      }}
    >
      <p>{tableId}</p>
      <span className="add" onClick={() => addTable(tableId)}>
        <i className="ni ni-fat-add" />
      </span>

      {hasListTable && (
        <span
          className="listTable"
          onClick={() => {
            listTable(tableId);
          }}
        >
          Të gjitha
        </span>
      )}
    </div>
  );
};

export default SingleTable;
