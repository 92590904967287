/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { UserService } from "../UserService";
// import * as jwtJsDecode from 'jwt-js-decode';
import decode from "jwt-decode";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      repeatPassword: "",
      resetSuccess: false,
    };
    this.resetPassword = this.resetPassword.bind(this);
  }

  async resetPassword() {
    const { repeatPassword, password } = this.state;
    if (!password || !repeatPassword) {
      alert(
        "Fusha për fjalëkalim dhe përseritje të fjalëkalimit është e obligueshme!"
      );
      return;
    }
    if (password.length < 6 || repeatPassword.length < 6) {
      alert("Fjalëkalimi duhet të jetë minimum 6 karaktere!");
      return;
    }
    if (password !== repeatPassword) {
      alert("Fjalëkalimi dhe përsitja e fjalëkalimit nuk përputhen!");
      return;
    }

    const uid = new URLSearchParams(this.props.location.search).get("uid");
    const token = new URLSearchParams(this.props.location.search).get("token");
    if (!uid || !token) {
      alert("Gabim, Linku është gabim, ju lutem provoni përseri!");
      return;
    }
    const userService = new UserService();
    try {
      const resetToken = await userService.resetPassword(uid, token, password);
      if (resetToken) {
        this.setState({ resetSuccess: true });
      } else {
        this.setState({ resetSuccess: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ resetSuccess: false });
    }
  }

  render() {
    const { repeatPassword, password, resetSuccess } = this.state;
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn || !decode(isLoggedIn)) {
    } else {
      let user = decode(isLoggedIn);
      if (user && user.user.role === "user") {
        // return <Redirect to='/admin/user-profile' />
      }
    }

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              {!resetSuccess ? (
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={password}
                        placeholder="Fjalëkalimi i ri"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        type="password"
                        autoComplete="new-password"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        value={repeatPassword}
                        placeholder="Përserit fjalëkalimin e ri"
                        onChange={(e) =>
                          this.setState({ repeatPassword: e.target.value })
                        }
                        type="password"
                        autoComplete="new-password"
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      onClick={this.resetPassword}
                      className="my-4"
                      color="primary"
                      type="button"
                    >
                      Ndrysho
                    </Button>
                  </div>
                </Form>
              ) : (
                "Fjalëkalimi u ndryshua me sukses!"
              )}
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default ResetPassword;
