import React, { useState, useEffect } from "react";
import * as axios from "axios";

import { UserService } from "../../UserService";

// reactstrap components
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmModal = ({
  toggleMainModalRender,
  confirmModalOpen,
  toggleConfirmModal,
  toggleListTableModalOpen,
  text,
  action,
  tableCreateId,
}) => {
  const deleteT = async (id) => {
    try {
      const userService = new UserService();
      const deletedTable = await userService.deleteTable(id);
      if (deletedTable !== undefined) {
        toggleMainModalRender();
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Sesioni juaj ka skaduar, ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        alert("Something went wrong!");
      }
    }
  };

  const confirmT = async (id) => {
    try {
      const userService = new UserService();
      const confirmedTable = await userService.confirmTable(id);
      if (confirmedTable !== undefined) {
        toggleMainModalRender();
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Sesioni juaj ka skaduar, ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        alert("Something went wrong!");
      }
    }
  };

  return (
    <Modal isOpen={confirmModalOpen} toggle={toggleConfirmModal}>
      <ModalHeader toggle={toggleConfirmModal}>
        <p></p>
      </ModalHeader>
      <ModalBody>
        <p>{text}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={() => {
            if (action === "delete") {
              deleteT(tableCreateId);
            }
            if (action === "confirm") {
              confirmT(tableCreateId);
            }
            toggleConfirmModal();
            toggleListTableModalOpen();
          }}
        >
          Konfirmo
        </Button>{" "}
        <Button color="primary" onClick={toggleConfirmModal}>
          Mbyll
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};
export default ConfirmModal;
