/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";

// core components
import { chartOptions, parseOptions } from "../variables/charts.js";

import Header from "../components/Headers/Header.js";
import { UserService } from "../UserService";
import decode from "jwt-decode";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      reservations: [],
      eventReservations: [],
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
  };
  async componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin" &&
      loggedInUser.user.role.toLowerCase() !== "author"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
    const userService = new UserService();
    const reservations = await userService.latest10Reservations();
    const eventReservations = await userService.latest10EventReservations();

    if (reservations && reservations.data) {
      this.setState({
        reservations: reservations,
      });
    }

    if (eventReservations && eventReservations.data) {
      this.setState({
        eventReservations: eventReservations,
      });
    }
  }

  render() {
    const { reservations, eventReservations } = this.state;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="6">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">10 Rezervimet e fundit</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="/admin/reservations"
                        size="sm"
                      >
                        Shiko të gjitha
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Emri Mbiemri</th>
                      <th scope="col">Nr. Tel</th>
                      <th scope="col">Restauranti</th>
                      <th scope="col">Statusi</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {reservations &&
                      reservations.data &&
                      reservations.data.length &&
                      reservations.data.map((reservation) => (
                        <tr key={reservation.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {reservation.name || "Ska Emer"}
                            </span>
                          </th>
                          <td>{reservation.phone || "Ska Nr Tel."}</td>
                          <td>
                            {reservation.restaurant === "1"
                              ? "Rruga B"
                              : "Qender"}
                          </td>
                          <td>
                            {reservation.status === "pending" ? (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"warning"}
                              >
                                NË PRITJE
                              </Alert>
                            ) : reservation.status === "confirmed" ? (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"success"}
                              >
                                KONFIRMUAR
                              </Alert>
                            ) : (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"danger"}
                              >
                                REFUZUAR
                              </Alert>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xl="6">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        10 Rezervimet e fundit për evente
                      </h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        href="/admin/events-reservations"
                        size="sm"
                      >
                        Shiko të gjitha
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Titulli</th>
                      <th scope="col">Nr. Tel</th>
                      <th scope="col">Restauranti</th>
                      <th scope="col">Statusi</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {eventReservations &&
                      eventReservations.data &&
                      eventReservations.data.length &&
                      eventReservations.data.map((reservation) => (
                        <tr key={reservation.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {reservation.name || "Ska Emer"}
                            </span>
                          </th>
                          <td>{reservation.phone || "Ska Nr Tel."}</td>
                          <td>
                            {reservation.restaurant === "1"
                              ? "Rruga B"
                              : "Qender"}
                          </td>
                          <td>
                            {reservation.status === "pending" ? (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"warning"}
                              >
                                NË PRITJE
                              </Alert>
                            ) : reservation.status === "confirmed" ? (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"success"}
                              >
                                KONFIRMUAR
                              </Alert>
                            ) : (
                              <Alert
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                color={"danger"}
                              >
                                REFUZUAR
                              </Alert>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;
