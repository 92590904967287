/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import { UserService } from "../UserService";
import moment from "moment";
import decode from "jwt-decode";

class Reservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservations: [],
      reservation: null,
      page: 1,
      activePage: 1,
      detailsModal: false,
      clickedNotification: [],
    };
  }

  async componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
    const userService = new UserService();
    const reservations = await userService.getAdminNotifications();
    if (reservations && reservations.data.length === 10) {
      this.setState((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        };
      });
    }
    if (reservations && reservations.data) {
      this.setState({
        reservations: reservations,
      });
    }
  }

  pageClick = async (page) => {
    const userService = new UserService();
    const reservationsPages = await userService.getAdminNotifications(page);
    if (reservationsPages.data.length) {
      this.setState({
        reservations: reservationsPages,
        activePage: page,
      });
    }
  };

  readNotification = async (id) => {
    const userService = new UserService();
    await userService.readNotification(id);
    this.setState((prevState) => ({
      clickedNotification: [...prevState.clickedNotification, id],
    }));
    //this.componentDidMount();
  };

  render() {
    const {
      reservations,
      page,
      activePage,
      detailsModal,
      reservation,
      clickedNotification,
    } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Njoftimet</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Tipi i njoftimit</th>
                      <th scope="col">Emri Mbiemri</th>
                      <th scope="col">Data</th>
                      <th scope="col">Nr. Tel</th>
                      <th scope="col">Përshkrimi</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {reservations &&
                      reservations.data &&
                      reservations.data.length &&
                      reservations.data.map((reservation) => (
                        <tr
                          onClick={() =>
                            reservation.seen === false
                              ? this.readNotification(reservation._id)
                              : console.log("seen")
                          }
                          style={{
                            cursor: "pointer",
                            fontWeight:
                              reservation.seen ||
                              clickedNotification.includes(reservation._id)
                                ? "normal"
                                : "bold",
                          }}
                          key={reservation.id}
                        >
                          <td scope="row">
                            <span className="mb-0 text-sm">
                              {reservation.type === "reservation"
                                ? "Kërkese për rezervim"
                                : reservation.type === "event_reservation"
                                ? "Kërkese për rezervim eventi"
                                : "Padefinuar"}
                            </span>
                          </td>
                          <td>
                            {reservation &&
                              reservation.meta &&
                              reservation.meta.name}
                          </td>
                          <td>
                            {(reservation &&
                              reservation.meta &&
                              moment(reservation.meta.created_at).format(
                                "DD/MM/YYYY"
                              )) ||
                              ""}
                          </td>
                          <td>
                            {reservation &&
                              reservation.meta &&
                              reservation.meta.phone}
                          </td>
                          <td>
                            {reservation &&
                              reservation.meta &&
                              reservation.meta.note}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {Array.apply(null, { length: activePage + 1 }).map(
                        (p, index) => {
                          return (
                            <PaginationItem
                              key={index}
                              className={
                                index + 1 === activePage ? "active" : ""
                              }
                            >
                              <PaginationLink
                                href="#"
                                onClick={(e) => this.pageClick(index + 1)}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        }
                      )}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Reservations;
