import * as axios from "axios";

export class UserService {
  constructor() {
    this.api = "";
  }

  axiosConstructor(isAuth) {
    const token = localStorage.getItem("token");
    return axios.create({
      baseURL: "https://api.izisapartment197.com/",
      timeout: 40000,
      headers: isAuth ? { Authorization: `Bearer ${token}` } : {},
    });
  }

  async login(email, password) {
    try {
      const user = await this.axiosConstructor().post("api/user/login", {
        email,
        password,
      });
      if (user.data.error) {
        alert("Diqka shkoi keq, ju lutem provoni perseri!");
      }
      return user;
    } catch (e) {
      alert("Diqka shkoi keq, ju lutem provoni perseri!");
    }
  }

  async resetPassword(userId, requestToken, newPassword) {
    try {
      const user = await this.axiosConstructor().patch(
        `api/user/change-password/${userId}`,
        {
          passwordResetToken: requestToken,
          newPassword: newPassword,
        }
      );
      if (user.data.error) {
        alert("Diqka shkoi keq, ju lutem provoni perseri!");
      }
      return user;
    } catch (e) {
      alert("Diqka shkoi keq, ju lutem provoni perseri!");
    }
  }

  async getReservations(page = 1, search) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations?page=${page}&search=${search}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async updateReservation(id, data) {
    try {
      return await this.axiosConstructor(true).patch(
        `api/reservations/${id}/update`,
        data
      );
    } catch (e) {
      console.log("error", e);
    }
  }

  async getReservationsByZone(page = 1, zone) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/by-zone/${zone}?page=${page}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getReservationsByStatus(page = 1, status) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/by-status/${status}?page=${page}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getEventReservationsByZone(page = 1, zone) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/by-zone/event-reservations/${zone}?page=${page}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }
  async getEventReservationsByStatus(page = 1, status) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/by-status/event-reservations/${status}?page=${page}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async latest10Reservations() {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/latest-reservations`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async latest10EventReservations() {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/latest-event-reservations`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getEventReservations(page = 1, search) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/event-reservations?page=${page}&search=${search}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getAdminNotifications(page = 1) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/admin-notifications?page=${page}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getPendingNotifications() {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/reservations/pending-notifications`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getAwardList(page = 1, search) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/user/users-awards/all?page=${page}&search=${search}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getAwardsHistory(page = 1, search) {
    try {
      const awardsHistory = await this.axiosConstructor(true).get(
        `api/user/awards-history?page=${page}&search=${search}`
      );
      return awardsHistory.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getAwardHistoryDetails(id) {
    try {
      const awardsHistory = await this.axiosConstructor(true).get(
        `api/user/awards-history/${id}`
      );
      return awardsHistory.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getUserAward(id) {
    return await this.axiosConstructor(true).get(`/api/user/award-users/${id}`);
  }

  async giveAward(id, points, description) {
    try {
      return await this.axiosConstructor(true).patch(
        `/api/user/award-users/${id}`,
        { points, description }
      );
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.error) {
        alert(e.response.data.error);
        return;
      }
      alert("Something went wrong!");
      console.log("error here", e.response.data.error);
    }
  }

  async getAdminSuggestions(page = 1) {
    try {
      const reservations = await this.axiosConstructor(true).get(
        `api/user/suggestions?page=${page}`
      );
      return reservations.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async readNotification(notificationId) {
    return await this.axiosConstructor(true).patch(
      `api/reservations/admin-notifications/read`,
      { id: notificationId }
    );
  }

  async getEvents(page = 1) {
    try {
      const events = await this.axiosConstructor(true).get(
        `api/events?page=${page}`
      );
      return events.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getComingEvents() {
    try {
      const events = await this.axiosConstructor(true).get(`api/events/coming`);
      return events.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getAnnouncements(page = 1) {
    try {
      const announcements = await this.axiosConstructor(true).get(
        `api/reservations/announcements/all?page=${page}`
      );
      return announcements.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async deleteAnnouncements(id) {
    return await this.axiosConstructor(true).delete(
      `api/reservations/announcements/${id}`
    );
  }

  async getNews(page = 1) {
    try {
      const news = await this.axiosConstructor().get(
        `api/reservations/news/all`
      );
      return news.data;
    } catch (e) {
      console.log("error", e);
    }
  }
  async getQRCodes(page = 1) {
    try {
      const news = await this.axiosConstructor(true).get(
        `api/user/qr-codes/all`
      );
      return news.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async createAnnouncement(data) {
    return await this.axiosConstructor(true).post(
      `api/reservations/announcement`,
      { ...data }
    );
  }

  async createNews(data) {
    return await this.axiosConstructor(true).post(`api/reservations/news`, {
      ...data,
    });
  }

  async createQRCode(data) {
    return await this.axiosConstructor(true).post(`api/user/generate-qr`, {
      ...data,
    });
  }

  async getAnnouncementById(id) {
    return await this.axiosConstructor(true).get(
      `api/reservations/announcement/${id}`
    );
  }

  async getNewsById(id) {
    return await this.axiosConstructor(true).get(`api/reservations/news/${id}`);
  }

  async getQrCodeById(id) {
    return await this.axiosConstructor(true).get(`api/user/qr-code/${id}`);
  }

  async deleteQR(id) {
    return await this.axiosConstructor(true).delete(`api/user/qr-code/${id}`);
  }

  async deleteNews(id) {
    return await this.axiosConstructor(true).delete(
      `api/reservations/news/${id}`
    );
  }

  async approveReservation(reservationId, message) {
    return await this.axiosConstructor(true).patch(
      `api/reservations/${reservationId}`,
      {
        status: "confirmed",
        messageForClient: message,
      }
    );
  }

  async rejectReservation(reservationId, message) {
    try {
      const events = await this.axiosConstructor(true).patch(
        `api/reservations/${reservationId}`,
        {
          status: "rejected",
          messageForClient: message,
        }
      );
      return events.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getTables(date, restaurant, isEvent) {
    try {
      const tables = await this.axiosConstructor(true).get(
        `api/reservations/table?date=${date}&restaurant=${restaurant}&isEvent=${isEvent}`
      );
      return tables.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getOneTable(date, restaurant, isEvent, tableId) {
    try {
      const tables = await this.axiosConstructor(true).get(
        `api/reservations/table/single?date=${date}&restaurant=${restaurant}&isEvent=${isEvent}&tableId=${tableId}`
      );
      return tables.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async createTable(data) {
    return await this.axiosConstructor(true).post(`api/reservations/table`, {
      ...data,
    });
  }

  async deleteTable(id) {
    return await this.axiosConstructor(true).delete(
      `api/reservations/table/${id}`
    );
  }

  async confirmTable(id) {
    return await this.axiosConstructor(true).patch(
      `api/reservations/table/${id}`
    );
  }

  async createEvent(data) {
    return await this.axiosConstructor(true).post(`api/events`, { ...data });
  }

  async updateEventEvent(data, id) {
    return await this.axiosConstructor(true).patch(`api/events/${id}`, {
      ...data,
    });
  }

  async updateNews(data, id) {
    return await this.axiosConstructor(true).patch(
      `api/reservations/news/${id}`,
      { ...data }
    );
  }

  async updateEvent(data, id) {
    return await this.axiosConstructor(true).patch(`api/events/update/${id}`, {
      ...data,
    });
  }

  async deleteEvent(id) {
    return await this.axiosConstructor(true).delete(`api/events/delete/${id}`);
  }

  async getEvent(id) {
    try {
      const event = await this.axiosConstructor(true).get(`api/events/${id}`);
      return event.data;
    } catch (e) {
      console.log("error", e);
    }
  }

  async getReservation(id) {
    return await this.axiosConstructor(true).get(`api/reservations/${id}`);
  }
  async getAdminSuggestion(id) {
    return await this.axiosConstructor(true).get(`api/user/suggestions/${id}`);
  }

  async getUsers(page = 1, search) {
    try {
      const users = await this.axiosConstructor(true).get(
        `api/user/all?page=${page}&search=${search}`
      );
      return users.data;
    } catch (e) {
      console.log("error", e);
    }
  }
}
