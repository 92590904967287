/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Spinner,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { UserService } from "../../UserService";
import decode from "jwt-decode";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
    };
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      // window.location.href = '/auth/login'
      return;
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() === "admin" &&
      loggedInUser.user.role.toLowerCase() === "author"
    ) {
      window.location.href = "/admin/index";
      return;
    }
  }

  async login() {
    const { email, password } = this.state;
    if (!email || !password) {
      alert("Fjalëkalimi dhe email janë të obligueshme!");
      return;
    }
    this.setState({ loading: true });
    const userService = new UserService();
    const userToken = await userService.login(email, password);
    if (userToken && userToken.data && userToken.data.jwt) {
      const loggedInUser = decode(userToken.data.jwt);
      if (
        loggedInUser &&
        loggedInUser.user.role &&
        loggedInUser.user.role.toLowerCase() !== "admin" &&
        loggedInUser.user.role.toLowerCase() !== "author"
      ) {
        alert("Not admin sorry!");
        this.setState({ loading: false });
        return;
        // return;
      }
      localStorage.setItem("token", userToken.data.jwt);
      this.setState({ loading: false });

      const userTokenAfter = localStorage.getItem("token");
      const loggedInUserAfter = decode(userTokenAfter);

      loggedInUserAfter.user.role.toLowerCase() === "author"
        ? this.props.history.push("/admin/events")
        : this.props.history.push("/admin/index");
    }
    this.setState({ loading: false });
  }

  render() {
    const { email, password } = this.state;
    const isLoggedIn = localStorage.getItem("token");
    if (!isLoggedIn || !decode(isLoggedIn)) {
    } else {
      let user = decode(isLoggedIn);
      if (user && user.user.role === "user") {
        // return <Redirect to='/admin/user-profile' />
      }
    }

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder="Email"
                      type="email"
                      autoComplete="new-email"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={password}
                      placeholder="Fjalëkalimi"
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      type="password"
                      autoComplete="new-password"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={this.login}
                    className="my-4"
                    color="primary"
                    type="button"
                  >
                    Sign in
                    {this.state.loading && (
                      <Spinner size={"sm"} animation="border" />
                    )}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Login;
