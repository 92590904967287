/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
// core components
import Header from "../components/Headers/Header.js";
import { UserService } from "../UserService";
import * as moment from "moment";
import decode from "jwt-decode";
// moment.locale('sq');

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      event: null,
      page: 1,
      activePage: 1,
      detailsModal: false,
    };
  }

  async componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin" &&
      loggedInUser.user.role.toLowerCase() !== "author"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }
    const userService = new UserService();
    const events = await userService.getEvents();
    if (events && events.data) {
      this.setState({
        events: events,
      });
    }
    if (events && events.data.length === 10) {
      this.setState((prevState) => {
        return {
          ...prevState,
          page: prevState.page + 1,
        };
      });
    }
    if (events && events.data) {
      this.setState({
        events: events,
      });
    }
  }

  viewDetails = async (id) => {
    const userService = new UserService();
    const event = await userService.getEvent(id);
    if (event && event.data) {
      this.setState({ event: event.data, detailsModal: true });
    }
  };

  deleteEvent = async (id) => {
    const userService = new UserService();
    const confirmation = window.confirm(
      "A jeni të sigurt që dëshironi të fshini?"
    );
    if (confirmation) {
      const { activePage } = this.state;
      const event = await userService.deleteEvent(id);
      const events = await userService.getEvents(activePage);
      this.setState({ events });
    }
  };

  editEvent = (id) => {
    window.location.href = "/admin/edit-event/" + id;
  };

  pageClick = async (page) => {
    const userService = new UserService();
    const eventsPage = await userService.getEvents(page);
    if (eventsPage.data.length) {
      this.setState({
        events: eventsPage,
        activePage: page,
      });
    }
  };

  toggle = () =>
    this.setState((prevState) => {
      return {
        ...prevState,
        detailsModal: !prevState.detailsModal,
      };
    });

  render() {
    const { events, page, activePage, detailsModal, event } = this.state;
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col" style={{ minHeight: "300px" }}>
              <Card className="shadow" style={{ minHeight: "300px" }}>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Eventet</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Nr.</th>
                      <th scope="col">Titulli</th>
                      <th scope="col" style={{ width: "40%" }}>
                        Përshkrimi
                      </th>
                      <th scope="col">Data</th>
                      <th scope="col">Restauranti</th>
                      <th scope="col">Aksion</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {events &&
                      events.data &&
                      events.data.length &&
                      events.data.map((event, i) => (
                        <tr key={event.id}>
                          <th scope="row">
                            <span className="mb-0 text-sm">{++i}</span>
                          </th>
                          <th scope="row">
                            <span className="mb-0 text-sm">
                              {event.title || "Ska titull"}
                            </span>
                          </th>
                          <td className="short-desc">
                            {event.description || "Ska përshkrim"}
                          </td>
                          <td>
                            {moment(event.date)
                              .locale("sq")
                              .format("DD/MM/YYYY") || "Ska date"}
                          </td>
                          <td>
                            {event.restaurant === "1" ? "Rruga B" : "Qender"}
                          </td>

                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => this.viewDetails(event.id)}
                                >
                                  Detajet
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => this.deleteEvent(event.id)}
                                >
                                  Fshije
                                </DropdownItem>
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => this.editEvent(event.id)}
                                >
                                  Ndrysho
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {Array.apply(null, { length: activePage + 1 }).map(
                        (p, index) => {
                          return (
                            <PaginationItem
                              key={index}
                              className={
                                index + 1 === activePage ? "active" : ""
                              }
                            >
                              <PaginationLink
                                href="#"
                                onClick={(e) => this.pageClick(index + 1)}
                              >
                                {index + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        }
                      )}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <div>
          <Modal isOpen={detailsModal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>
              <h2>Detajet e eventit</h2>
            </ModalHeader>
            <ModalBody>
              {event && event.photo && (
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <img
                    style={{ maxWidth: "400px", alignSelf: "center" }}
                    src={event.photo}
                  />
                </div>
              )}
              <p style={{ marginTop: "30px" }}>
                <b>ID:</b> {event && event.id}
              </p>
              <p>
                <b>Titulli:</b> {event && event.title}
              </p>
              <p>
                <b>Data:</b> {event && moment(event.date).format("DD/MM/YYYY")}
              </p>
              <p>
                <b>Përshkrimi:</b> {event && event.description}
              </p>
              <p>
                <b>Restauranti:</b>
                {event &&
                  event.restaurant &&
                  (event.restaurant === "1" ? " Rruga B" : " Qender")}
              </p>
              <p>
                <b>Statusi:</b>
                {(event &&
                  event.status &&
                  (event.status === "coming"
                    ? " I ardhshëm"
                    : event.status === "rejected"
                    ? " Refuzuar"
                    : event.status === "confirmed"
                    ? " Konfirmuar"
                    : event.status === "pending"
                    ? " Në pritje"
                    : " I padefinuar")) ||
                  " I padefinuar"}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Mbyll
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}

export default Events;
