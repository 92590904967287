/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Label,
  Col,
} from "reactstrap";
import { UserService } from "../UserService";
import decode from "jwt-decode";
import { post } from "axios";
import moment from "moment";

class EditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      restaurant: "1",
      title: "",
      description: "",
      date: "",
      hour: "",
      status: "coming",
      imageCover: null,
      notifyAll: false,
      photo: "",
    };
  }

  fileUpload = (e) => {
    console.log("event", e.target.value);
    const formData = new FormData();
    let data = e.target.files[0];

    formData.append("image", data);
    this.setState({ imageCover: formData });
    // console.log("fd", formData.config)
    // return
  };

  async componentDidMount() {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      window.location.href = "/auth/login";
    }
    const loggedInUser = decode(userToken);
    if (
      loggedInUser &&
      loggedInUser.user.role &&
      loggedInUser.user.role.toLowerCase() !== "admin" &&
      loggedInUser.user.role.toLowerCase() !== "author"
    ) {
      alert("Unauthorized!");
      window.location.href = "/auth/login";
      return;
    }

    const eventId = window.location.pathname.split("/")[3];
    const userService = new UserService();
    try {
      const currentEvent = await userService.getEvent(eventId);
      console.log("event ", currentEvent.data);
      const { photo, restaurant, title, description, date, hour } =
        currentEvent.data;
      this.setState({ photo, title, description, date, hour, restaurant });
    } catch (e) {
      console.log("error", e);
    }
  }

  updateCurrentEvent = async () => {
    const {
      restaurant,
      title,
      description,
      date,
      hour,
      status,
      imageCover,
      notifyAll,
      photo,
    } = this.state;
    if (!restaurant.trim()) {
      alert("Restauranti është fushë e obligushme");
      return;
    }
    if (!title.trim()) {
      alert("Titulli është fushë e obligushme");
      return;
    }
    if (!description.trim()) {
      alert("Përshkrimi është fushë e obligushme");
      return;
    }
    if (!date.trim()) {
      alert("Data është fushë e obligushme");
      return;
    }
    if (!hour.trim()) {
      alert("Ora është fushë e obligushme");
      return;
    }
    // if(!imageCover) {
    //   alert("Foto është fushë e obligushme")
    //   return;
    // }
    if (!status.trim()) {
      alert("Statusi është fushë e obligushme");
      return;
    }
    try {
      const userService = new UserService();
      const eventId = window.location.pathname.split("/")[3];
      const user = await userService.updateEvent(
        { restaurant, title, description, date, hour, status, notifyAll },
        eventId
      );

      if (
        user &&
        user.data &&
        user.data.data &&
        user.data.data.id &&
        this.state.imageCover
      ) {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const url = `https://api.izisapartment197.com/api/user/upload/${user.data.data.id}`;
        const photoUpload = await post(url, this.state.imageCover, config);
        if (
          photoUpload &&
          photoUpload.data &&
          photoUpload.data &&
          photoUpload.data.filename
        ) {
          const updateEvent = await userService.updateEventEvent(
            { imagePath: photoUpload.data.filename },
            user.data.data.id
          );
        }
      }
      window.location.href = "/admin/events";
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.removeItem("token");
        alert("Ju lutemi kyquni");
        window.location.href = "/auth/login";
      } else {
        console.log("error", e.response);
        alert("Something went wrong!");
      }
    }
  };
  render() {
    const { photo, title, description, date, hour, restaurant } = this.state;
    return (
      <>
        <Col lg="6" md="8" className={"center mt-5"}>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <h3>Ndrysho eventin</h3>
              </div>
              <Form role="form">
                <FormGroup>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {photo && <img style={{ maxWidth: "200px" }} src={photo} />}
                  </div>
                </FormGroup>
                <FormGroup>
                  <FormGroup>
                    <Label for="status">
                      Restauranti:{" "}
                      <small>{restaurant === "1" ? "Rruga B" : "Qender"}</small>
                    </Label>
                    <InputGroup className="input-group-alternative mb-3">
                      <Input
                        type="select"
                        onChange={(e) =>
                          this.setState({ restaurant: e.target.value })
                        }
                        name="select"
                        id="status"
                      >
                        <option value={"1"}>Rruga B</option>
                        <option value={"2"}>Qender</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Titulli"
                      type="text"
                      value={title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      value={description}
                      placeholder="Përshkrim"
                      max={3000}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      type="textarea"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="data">
                    Data:{" "}
                    <small>
                      (Data e eventit: {moment(date).format("DD/MM/YYYY")})
                    </small>
                  </Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Data"
                      id="data"
                      onChange={(e) => {
                        if (e.target.value) {
                          this.setState({
                            date: new Date(e.target.value || "").toISOString(),
                          });
                        }
                      }}
                      type="date"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <Label for="time">
                    Ora: (<small>{hour}</small>)
                  </Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      placeholder="Ora"
                      id={"time"}
                      onChange={(e) => this.setState({ hour: e.target.value })}
                      type="time"
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <Label for="exampleFile">
                    Foto{" "}
                    <small>
                      (Ngarko foto nëse dëshiron të ndryshosh foton e eventit)
                    </small>
                  </Label>
                  <Input
                    onChange={this.fileUpload}
                    type="file"
                    name="file"
                    id="exampleFile"
                  />
                </FormGroup>

                <FormGroup className={"ml-3"}>
                  <Label check>
                    <InputGroup className="input-group-alternative mb-3">
                      <Input
                        onChange={(e) =>
                          this.setState({ notifyAll: e.target.checked })
                        }
                        type="checkbox"
                      />{" "}
                      Njofto të gjithë përdoruesit
                    </InputGroup>
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label for="status">Statusi:</Label>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input type="select" disabled name="select" id="status">
                      <option value={"coming"}>I ardhshëm</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    onClick={this.updateCurrentEvent}
                    className="mt-4"
                    color="primary"
                    type="button"
                  >
                    Ndrysho eventin
                  </Button>
                  <Button
                    onClick={() => this.props.history.goBack()}
                    className="mt-4"
                    color="warning"
                    type="button"
                  >
                    Anulo
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default EditEvent;
